export function streamStateBadgeVariant(state) {
  const badges = {
    PENDING: 'warning',
    READY: 'primary',
    STARTING: 'info',
    RUNNING: 'success',
    STOPPING: 'info',
    FINISHED: 'secondary',
    FAILED: 'danger',
  };

  return badges[state] || 'secondary';
}

export function formatStreamStateBadge(state) {
  return state.toLowerCase()
    .replace(/\b\w/g, x=> x.toUpperCase());
}

export function formatProviderName(provider) {
  const providers = {
    elemental: 'AWS Elemental 30p Protected',
    elemental_24p: 'AWS Elemental 24p Protected',
    elemental_30p_dvr: 'AWS Elemental 30p Protected with DVR',
    elemental_30p_public: 'AWS Elemental 30p Unprotected (Dangerous!)',
    elemental_30p_public_dvr: 'AWS Elemental 30p Unprotected with DVR (Dangerous!)',
    nop: 'No-Op Dummy',
    bitmovin: 'Bitmovin',
  };

  return providers[provider] || provider;
}
