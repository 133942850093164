<template>
  <b-container>
    <h1>Roles</h1>

    <b-alert v-if="error" variant="danger" show>{{error}}</b-alert>

    <loading-spinner v-if="loading"/>
    <b-table v-else :items="roles" :fields="fields">
      <template v-slot:cell(name)="data">
        <router-link :to="`/admin/roles/${data.item.name}`">
          {{data.value}}
        </router-link>
      </template>
      <template v-slot:cell(actions)="data">
        <router-link :to="`/admin/roles/${data.item.name}`" class="action-btn">
          <fa-icon icon="pencil-alt"/>
        </router-link>
        <a href="#" @click.prevent="confirmDeleteRole(data.item)" class="action-btn"
          v-if="hasGlobalPermission('manageRoles')">
          <fa-icon icon="trash-alt"/>
        </a>
      </template>
    </b-table>

    <div>
      <b-button v-if="hasGlobalPermission('manageRoles')" variant="primary"
        to="/admin/roles/create">
        <fa-icon icon="plus"/> Create Role
      </b-button>
    </div>

  </b-container>
</template>

<script>
  import {mapGetters, mapState, mapActions} from 'vuex';

  import LoadingSpinner from '@/components/LoadingSpinner';

  export default {
    name: 'Roles',

    components: {
      LoadingSpinner,
    },

    mounted() {
      this.loadRoles();
    },

    computed: {
      ...mapGetters('auth', ['hasGlobalPermission']),
      ...mapState('roles', [
        'roles',
        'loading',
        'error',
      ]),
    },

    methods: {
      ...mapActions('roles', ['loadRoles', 'deleteRole']),
      async confirmDeleteRole(role) {
        let confirmation = await this.$bvModal.msgBoxConfirm(
          `Delete role "${role.name}"?  All permission assignments will be lost.`,
          { okVariant: 'danger', okTitle: 'Delete', 
            title: 'Confirm Deletion'});
        if(confirmation) {
          this.deleteRole(role.name);
        }
      },
    },

    data() {
      return {
        fields: [
          'name',
          'description',
          'actions',
        ],
      }
    },
  }
</script>

<style scoped>
  .action-btn {
    color: gray;
    margin-right: 0.5em;
  }

  .action-btn:hover {
    color: black;
  }
</style>
