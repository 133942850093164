<template>
  <b-table :items="log" :fields="fields">
    
  </b-table>
</template>

<script>
  import {mapState} from 'vuex';
  import moment from 'moment';

  export default {
    name: 'EventLogTable',
    props: {
      log: Array
    },

    computed: {
      ...mapState('events', ['eventStates']),
    },

    methods: {
      formatDate(x) {
        if(!x) return "";
        return moment(x).format("YYYY MMM D h:mm:ss A");
      },
    },

    data() {
      return {
        fields: [
          {key: 'state', formatter: x => this.eventStates[x].title},
          {key: 'start_time', formatter: 'formatDate'},
          {key: 'end_time', formatter: 'formatDate'},
        ]
      };
    },
  }
</script>

<style scoped>
  
</style>
