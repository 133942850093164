<template>
  <div id="app">
    <div id="nav">
      <main-nav/>
    </div>
    <div id="main">
      <router-view v-if="authenticated"/>
      <login v-else-if="connected"/>
      <connect-spinner v-else/>
    </div>
  </div>
</template>

<script>
  import {mapState} from 'vuex';

  import MainNav from '@/components/MainNav';
  import Login from '@/views/Login';
  import ConnectSpinner from '@/views/ConnectSpinner';

  export default {
    name: 'App',

    components: {
      MainNav,
      Login,
      ConnectSpinner,
    },

    computed: {
      ...mapState('auth', [
        'connected',
        'authenticated',
      ]),
    }
  }
</script>

<style>
  #nav {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
  }

  #main {
    margin-top: 64px;
    margin-bottom: 16px;
  }

  .button-box {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .button-box > * {
    margin-right: 1em;
  }
</style>
