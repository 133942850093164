<template>
  <div class="truncated" :style="`max-width: ${maxWidth}px`">
    <slot></slot>
  </div>
</template>

<script>
  export default {
    name: 'Truncated',
    props: {
      maxWidth: {
        default: 300,
      }
    }
  }
</script>

<style scoped>
  .truncated {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
  }
</style>
