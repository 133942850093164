<template>
  <b-container>
    <h1 class="mb-3">{{title}}</h1>

    <b-alert variant="danger" show v-if="error">{{error}}</b-alert>

    <div class="header-box mb-3">
      <div class="button-box">
        <slot name="buttons"></slot>
      </div>

      <b-form inline>
        <b-input id="search-input" type="search" v-model="search" 
          :placeholder="searchText"/>
      </b-form>
    </div>

    <loading-spinner v-if="!items && !error"/>
    <div v-else-if="count == 0">
      <em>{{noResultsText}}</em>
    </div>
    <div v-else>
      <slot name="table">
        <b-table :items="items" :fields="fields"/>
      </slot>
      <div class="pagebox">
        <b-pagination v-if="count > pagesize" v-model="curPage"
          :total-rows="count" :per-page="pagesize" class="mr-2"/>
        <b-spinner v-if="pageBusy"/>
      </div>
    </div>

    <div v-if="count > 0" class="button-box">
      <slot name="buttons"></slot>
    </div>

  </b-container>
</template>

<script>
  import {debounce} from 'lodash';

  import LoadingSpinner from '@/components/LoadingSpinner';

  export default {
    name: 'PaginatedList',
    props: {
      title: String,
      error: String,
      searchPlaceholder: String,
      emptyPlaceholder: String,
      items: Array,
      count: Number,
      fields: Array,
      page: Number,
      pagesize: Number,
      pageBusy: Boolean,
    },

    components: {
      LoadingSpinner,
    },

    computed: {
      searchText() {
        return this.searchPlaceholder || `Search ${this.title}`;
      },
      noResultsText() {
        return this.emptyPlaceholder || 'No results.';
      },
      curPage: {
        get() {
          return this.page;
        },
        set(v) {
          this.$emit('loadPage', v);
        }
      }
    },

    methods: {
      performSearch: debounce(function() {
        this.$emit('search', this.search);
      }, 500),
    },

    data() {
      return {
        search: null,
      };
    },

    watch: {
      search() {
        this.$emit('invalidate');
        this.performSearch();
      },
    },

  }
</script>

<style scoped>
  .pagebox {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .header-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
</style>
