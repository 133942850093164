<template>
  <b-container>
    <h1>What can...</h1>
    <div>
      What can
      <object-finder type="user" v-model="user" @input="getPermissions"/>
      do with
      <object-finder v-model="target" @input="getPermissions"/>
      ?
    </div>

    <b-alert variant="danger" v-if="error" show>{{error}}</b-alert>

    <loading-spinner v-if="busy"/>
    <div v-if="permissions" class="results">
      <div v-if="permissions.length == 0">
        <em>Nothing.</em>
      </div>
      <div v-else >
        They can:
        <ul class="permission-list">
          <li v-for="permission in permissions" :key="permission">
            {{permission}}
          </li>
        </ul>
      </div>
    </div>

  </b-container>
</template>

<script>
  import io from '@/socket-instance';

  import ObjectFinder from '@/components/ObjectFinder';
  import LoadingSpinner from '@/components/LoadingSpinner';

  export default {
    name: 'WhatCan',
    components: {
      ObjectFinder,
      LoadingSpinner,
    },

    methods: {
      getPermissions() {
        this.permissions = null;
        if(this.user && this.target) {
          this.busy = true;
          const args = {
            user: this.user,
            target: this.target,
          };
          io.emit('auth/getPermissionsOnObject', args, (result) => {
            if(result.error) {
              this.error = error;
            } else {
              this.permissions = result.permissions;
            }
            this.busy = false;
          });
        }
      }
    },

    data() {
      return {
        user: null,
        target: null,
        busy: false,
        error: null,
        permissions: null,
      };
    },
  }
</script>

<style scoped>
  .results {
    margin-top: 32px;
  }
</style>
