<template>
  <div class="connect-container">
    <b-spinner class="connect-spinner"/>
  </div>
</template>

<script>
  export default {
    name: 'ConnectSpinner'
  }
</script>

<style scoped>
  .connect-container {
    margin-left: auto;
    margin-right: auto;
    padding-top: 64px;
    display: flex;
    justify-content: center;
  }

  .connect-spinner {
    width: 3em;
    height: 3em;
  }
</style>
