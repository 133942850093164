<template>
  <div>
    <loading-spinner v-if="!roles"/>
    <b-table v-else-if="roles.length > 0" :items="roles" :fields="roleFields">
      <template v-slot:cell(role)="data">
        <router-link :to="`/admin/roles/${data.value}`">{{data.value}}</router-link>
      </template>
      <template v-slot:cell(scope)="data">
        <span v-if="data.item.kind != 'global'" class="mr-3">{{data.item.title}}</span>
        <b-badge :variant="objectBadgeVariant(data.item.kind)">
          {{formatObjectBadge(data.item.kind)}}
        </b-badge>
      </template>
      <template v-slot:cell(actions)="data">
        <a href="#" class="action-btn" @click="confirmDeleteRole(data.item)">
          <fa-icon icon="times"/>
        </a>
      </template>
    </b-table>
    <div v-else class="no-roles">
      This user has no assigned roles.
    </div>
    <div class="button-box mt-3">
      <assign-role-modal v-if="canAssignRoles" :user="this.user"
        @assigned="reload"/>
    </div>
  </div>
</template>

<script>
  import io from '@/socket-instance';
  import {objectBadgeVariant, formatObjectBadge} from '@/common/objects';

  import LoadingSpinner from '@/components/LoadingSpinner';
  import AssignRoleModal from '@/components/AssignRoleModal';

  export default {
    name: 'UserRoleTable',
    props: ['user'],

    components: {
      LoadingSpinner,
      AssignRoleModal
    },

    mounted() {
      this.reload();
    },

    methods: {
      reload() {
        this.roles = null;
        this.error = null;

        io.emit('users/loadUserRoles', this.user, (result) => {
          if(result.error) {
            this.error = result.error;
          } else {
            this.roles = result.roles;
          }
        });
      },
      objectBadgeVariant,
      formatObjectBadge,
      async confirmDeleteRole(role) {
        const confirmation = await this.$bvModal.msgBoxConfirm(
          `Revoke role "${role.role}" with scope "${role.title}"?`,
          { okVariant: 'danger', okTitle: 'Revoke Role', 
            title: 'Confirm Revocation'});
        if(confirmation) {
          const args = {
            user: this.user,
            role: role.role,
            target: role.target
          };
          io.emit('users/revokeRole', args, (result) => {
            if(result.error) {
              this.error = result.error;
            } else {
              this.reload();
            }
          });
        }

      },
    },

    computed: {
      canAssignRoles() {
        return this.$store.getters['auth/hasGlobalPermission']('assignRoles');
      },
    },

    data() {
      return {
        roles: null,
        error: null,

        roleFields: [
          'role',
          'scope',
          'actions',
        ],        
      }
    },

    watch: {
      user() {
        this.reload();
      },
    }
  }
</script>

<style scoped>
  .action-btn {
    color: gray;
    margin-right: 0.5em;
  }

  .action-btn:hover {
    color: black;
  }

  .no-roles {
    color: gray;
    font-style: italic;
  }
</style>
