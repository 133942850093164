export function objectBadgeVariant(kind) {
  const badges = {
    global: 'danger',
    artist_brand: 'info',
    event: 'success',
    stream: 'primary',
  };
  return badges[kind] || 'secondary';
}

export function formatObjectBadge(kind) {
  return kind
    .replace('_', ' ')
    .replace(/\b\w/g, x => x.toUpperCase());
}

export function formatObjectName(kind) {
  return kind.replace('_', ' ');
}

