<template>
  <div class="loading-container">
    <b-spinner class="loading-spinner"/>
  </div>
</template>

<script>
  export default {
    name: 'LoadingSpinner'
  }
</script>

<style scoped>
  .loading-container {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
  }

  .loading-spinner {
    width: 3em;
    height: 3em;
  }
</style>
