<template>
  <paginated-list title="Events" :error="error" :items="items" :count="count"
    :page-busy="pageBusy" :pagesize="pagesize" :page="page"
    empty-placeholder="No events found."
    @invalidate="INVALIDATE_RESULTS" @search="listEvents" @loadPage="loadPage">

    <template v-slot:buttons>
      <b-button variant="primary" v-if="canCreate" to="/admin/events/create">
        <fa-icon icon="plus"/> Create Event
      </b-button>
    </template>

    <template v-slot:table>
      <b-table :items="items" :fields="fields">
        <template v-slot:cell(title)="data">
          <router-link :to="`/admin/events/${data.item.slug}`">
            <trunc><strong>{{data.value}}</strong></trunc>
          </router-link>
        </template>
        <template v-slot:cell(artist_brand_title)="data">
          <router-link :to="`/admin/brands/${data.item.artist_brand_slug}`">
            <trunc>{{data.value}}</trunc>
          </router-link>
        </template>
      </b-table>
    </template>

  </paginated-list>
</template>

<script>
  import {mapState, mapActions, mapMutations} from 'vuex';
  import {formatDateTime, formatMinutes} from '@/common/times';

  import PaginatedList from '@/components/PaginatedList';

  export default {
    name: 'Events',
    components: {
      PaginatedList
    },

    mounted() {
      this.listEvents();
    },

    computed: {
      ...mapState('events', [
        'items',
        'error',
        'pageBusy',
        'count',
        'pagesize',
        'page',
      ]),
      canCreate() {
        return this.$store.getters['auth/hasGlobalPermission']('createEvents');
      },
    },

    methods: {
      ...mapActions('events', [
        'listEvents',
        'loadPage',
      ]),
      ...mapMutations('events', [
        'INVALIDATE_RESULTS',
      ]),
    },

    data() {
      return {
        fields: [
          { key: 'title', label: 'Event Title' },
          { key: 'artist_brand_title', label: 'Artist Brand' },
          { key: 'created_at', label: 'Created', formatter: formatDateTime},
          { key: 'tickets', label: 'Valid Tickets', formatter: (value, key, item) => {
            const valid = item.tickets_created - item.tickets_revoked;
            if(!valid || valid < 0) return 0;
            return valid;
          }},
        ]
      };
    },
  }
</script>

<style scoped>

</style>
