import io from '@/socket-instance';

export default {
  namespaced: true,

  state: {
    authenticated: false,
    connected: false,
    authError: null,
    authBusy: false,
    user: null,
    permissions: [],
  },

  getters: {
    token(state, getters, rootState) {
      return rootState.local.token;
    },
    hasGlobalPermission(state) {
      return (permission) => {
        if(!state.user) return false;
        if(state.user.is_super) return true;
        return state.permissions.includes(permission);
      };
    },
    isSuper(state) {
      if(!state.user) return false;
      if(state.user.is_super) return true;
    },
  },

  mutations: {
    DISCONNECT(state) {
      state.authenticated = false;
      state.connected = false;
      state.authError = null;
      state.authBusy = false;
    },
    AUTHENTICATE(state, user) {
      state.authenticated = true;
      state.connected = true;
      state.authError = null;
      state.authBusy = false;
      state.user = user;
    },
    SET_PERMISSIONS(state, permissions) {
      state.permissions = permissions;
    },
    DEAUTHENTICATE(state, error) {
      state.authenticated = false;
      state.connected = true;
      state.authError = error ? error : null;
      state.authBusy = false;
      state.user = null;
      state.permissions = [];
    },
    START_AUTHENTICATION(state) {
      state.authError = null;
      state.authBusy = true;
    },
  },

  actions: {
    socket_connect({commit, dispatch, getters}) {
      if(getters.token) {
        dispatch('authLoginWithToken', getters.token);
      } else {
        commit('DEAUTHENTICATE');
      }
    },
    socket_disconnect({commit}) {
      commit('DISCONNECT');
    },
    authLogin({commit}, {email, password}) {
      commit('START_AUTHENTICATION');
      io.emit('auth/login', {email, password}, (result) => {
        if(result.error) {
          commit('DEAUTHENTICATE', result.error);
        } else {
          commit('AUTHENTICATE', result.user);
          commit('SET_PERMISSIONS', result.permissions);
          commit('local/SET_TOKEN', result.token, {root: true});
        }
      });
    },
    authLoginWithToken({commit}, token) {
      commit('START_AUTHENTICATION');
      io.emit('auth/loginWithToken', token, (result) => {
        if(result.error) {
          if(result.error.includes('TokenExpiredError')) {
            commit('DEAUTHENTICATE', null);
          } else {
            commit('DEAUTHENTICATE', result.error);
          }
        } else {
          commit('AUTHENTICATE', result.user);
          commit('SET_PERMISSIONS', result.permissions);
        }
      });
    },
    authLogout({commit}) {
      io.emit('auth/logout');
      commit('DEAUTHENTICATE');
      commit('local/SET_TOKEN', null, {root: true});
    },
  },
}
