<template>
  <div class="object-finder">
    <b-dropdown variant="outline-secondary" ref="dropdown" :disabled="disabled" block>
      <template v-slot:button-content>
        <div v-if="selection" class="selection-box">
          <div class="selection-row">
            <b-spinner small v-if="getting"/>
            <template v-else>
              <div class="selection-title">{{selection.title}}</div>
              <b-badge :variant="objectBadgeVariant(selection.kind)">
                {{formatObjectBadge(selection.kind)}}
              </b-badge>
            </template>
          </div>
        </div>
        <div v-else class="selection-box">Choose {{typeText}}</div>
      </template>
      <b-dropdown-form @submit.stop.prevent="() => {}">
        <b-input id="object-search" :placeholder="`Search ${typeText}s`"
          v-model="query" class="mb-3"/>

        <b-alert v-if="error" variant="danger" show>{{error}}</b-alert>
        <div v-else-if="busy" class="busy-spinner">
          <b-spinner/>
        </div>
        <div v-else-if="objects">
          <b-list-group class="mb-1">
            <b-list-group-item v-for="obj in objects" :key="obj.id"
              href="#" @click.prevent="select(obj)">
              <div class="obj-title-row">
                <div class="obj-title">{{obj.title}}</div>
                <b-badge :variant="objectBadgeVariant(obj.kind)" class="ml-1">
                  {{formatObjectBadge(obj.kind)}}
                </b-badge>
              </div>
              <div class="detail-row">
                {{obj.slug}}
              </div>
            </b-list-group-item>
          </b-list-group>
        </div>
        <div v-if="count > limit" class="page-box">
          <b-pagination v-model="page" :total-rows="count" :per-page="limit"
            class="mr-3"/>
          <b-spinner v-if="pageBusy"/>
        </div>
      </b-dropdown-form>
    </b-dropdown>
  </div>
</template>

<script>
  import {debounce} from 'lodash';
  import {objectBadgeVariant, formatObjectBadge, formatObjectName}
    from '@/common/objects';
  import io from '@/socket-instance';

  export default {
    name: 'ObjectFinder',
    props: ['type', 'value', 'disabled'],

    mounted() {
      if(this.value) {
        //this.query = this.value;
        this.getObject(this.value);
      }
    },

    computed: {
      types() {
        if(this.type === undefined) {
          return null;
        } else if(typeof this.type === "string") {
          return [this.type];
        } else {
          return this.type;
        }
      },
      typeText() {
        return !this.types || this.types.length > 1? 'object' : this.types[0];
      }
    },

    methods: {
      objectBadgeVariant,
      formatObjectBadge,
      formatObjectName,
      search: debounce(function() {
        const args = {
          query: this.query,
          limit: this.limit,
          kinds: this.types,
        };
        io.emit('objects/searchObjects', args, (result) => {
          if(!result.error) {
            this.error = null;
            this.objects = result.objects;
            this.count = result.count;
            this.page = 1;
          } else {
            this.error = result.error;
          }
          this.busy = false;
          this.pageBusy = false;
        });
      }, 300),
      getObject(id) {
        this.getting = true;
        io.emit('objects/getObject', id, (result) => {
          this.getting = false;
          if(result.result) {
            this.selection = result.result;
          }
        });
      },
      loadPage(page) {
        const lastPage = Math.ceil(this.count / this.limit);
        if(page > lastPage) page = lastPage;
        this.error = null;
        this.pageBusy = true;
        const args = {
          query: this.query,
          limit: this.limit,
          page: this.page,
          kinds: this.types,
        };
        io.emit('objects/searchObjects', args, (result) => {
          if(!result.error) {
            this.error = null;
            this.objects = result.objects;
          } else {
            this.error = result.error;
          }
          this.pageBusy = false;
        });
      },
      select(obj) {
        this.selection = obj;
        this.$emit('input', obj.id);
        this.$refs.dropdown.hide(true);
      },
    },

    data() {
      return {
        limit: 5,
        count: 0,
        query: '',
        objects: [],
        busy: false,
        pageBusy: false,
        error: null,
        page: 1,
        selection: null,
        getting: false,
      }
    },

    watch: {
      query(query) {
        this.error = null;
        if(!query) {
          this.objects = null;
          this.busy = false;
          this.count = 0;
        } else {
          this.objects = null;
          this.busy = true;
          this.search();
        }
      },
      value(value) {
        this.getObject(this.value);
      },
      page(p) {
        this.loadPage(p);
      }
    }
  }
</script>

<style scoped>
  #object-search {
    min-width: 300px;
  }

  .object-finder {
    display: inline-block;
    min-width: 300px;
  }

  .busy-spinner {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .obj-title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    margin-right: 0.5em;
  }

  .obj-title-row {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    max-width: 300px;
  }

  .selection-box {
    display: inline-block;
  }

  .selection-title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    margin-right: 0.5em;
  }

  .selection-row {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    max-width: 300px;
  }

  .detail-row {
    font-size: 9pt;
    color: gray;
  }

  .page-box {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
</style>
