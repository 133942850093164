<template>
  <b-container>
    <b-alert variant="danger" v-if="loading && error" show>{{error}}</b-alert>
    <loading-spinner v-else-if="loading"/>
    <template v-else>
      <h1>{{title}}</h1>

      <b-form>
        <b-form-group id="name-group" label="Name:" label-for="input-name"
          :invalid-feedback="nameFeedback">
          <b-form-input id="input-name" v-model="role.name" trim 
            :disabled="!isNew" :state="validateName"></b-form-input>
        </b-form-group>
        <b-form-group id="description-group" label="Description:" 
          label-for="input-description">
          <b-form-input id="input-description" v-model="role.description">
          </b-form-input>
        </b-form-group>

        <h2>Targets</h2>
        <p>
          Objects on which this role may be granted:
        </p>
        <b-form-checkbox-group v-model="role.targets">
          <ul class="target-options">
            <li v-for="target in permissions.targets" :key="target.name">
              <b-form-checkbox :value="target.name">{{target.title}}</b-form-checkbox>
            </li>
          </ul>
        </b-form-checkbox-group>

        <h2>Permissions</h2>
        <p>
          Users with this role are allowed to:
        </p>
        <b-form-checkbox-group v-model="role.permissions">
          <div v-for="module in permissions.modules" :key="module.name">
            <h5>{{module.title}}</h5>
            <ul class="permission-options">
              <li v-for="permission in module.permissions" :key="permission.name">
                <b-form-checkbox :value="permission.name">
                  {{permission.title}}<br/>
                  <div class="description">{{permission.description}}</div>
                </b-form-checkbox>
              </li>
            </ul>
          </div>
        </b-form-checkbox-group>

      </b-form>
      
      <b-alert variant="danger" v-if="error" show>{{error}}</b-alert>

      <div class="buttons">
        <b-button variant="primary" v-if="isNew" :disabled="busy" @click="save">
          <fa-icon icon="plus"/> Create Role
        </b-button>
        <b-button variant="primary" v-else :disabled="busy" @click="save">
          <fa-icon icon="save"/> Save Role
        </b-button>
        <b-button variant="danger" v-if="!isNew" :disabled="busy" @click="confirmDelete">
          <fa-icon icon="trash-alt"/> Delete Role
        </b-button>
        <b-spinner v-if="busy"/>
      </div>
    </template>
  </b-container>
</template>

<script>
  import {mapState, mapActions} from 'vuex';
  import {debounce} from 'lodash';
  import io from '@/socket-instance';

  import LoadingSpinner from '@/components/LoadingSpinner';

  export default {
    name: 'EditRole',

    components: {
      LoadingSpinner,
    },

    mounted() {
      this.routeChanged();
      if(this.permissions === null) {
        this.loadPermissions();
      }
    },

    computed: {
      ...mapState('roles', ['permissions']),
      isNew() {
        return this.$route.params.name == "create";
      },
      title() {
        return this.isNew? "New Role" : "Edit Role";
      },
      loading() {
        return this.role === null || this.permissions === null;
      },
      validateName() {
        if(this.role.name === undefined) return null;
        if(this.role.name.length == 0) return null;
        if(! /^[a-zA-Z0-9\-_]+?$/.test(this.role.name)) {
          return false;
        }
        return this.nameValid;
      },
      nameFeedback() {
        if(this.nameValid === false) {
          return 'This role name is already in use.';
        } else {
          return 'Invalid characters in role name.';
        }
      },
    },

    methods: {
      ...mapActions('roles', ['loadPermissions']),
      routeChanged() {
        if(this.isNew) {
          this.newRole();
        } else {
          this.loadRole(this.$route.params.name);
        }
      },
      newRole() {
        this.role = {
          name: '',
          description: '',
          permissions: [],
          targets: [],
        };
        this.error = null;
      },
      loadRole(name) {
        this.role = null;
        this.error = null;
        console.log('loadRole');
        io.emit('roles/getRole', name, (result) => {
          if(result.error) {
            this.error = result.error;
          } else {
            this.role = result.role;
          }
        });
      },
      save() {
        if(this.isNew && !this.validateName) {
          console.log('invalid role name');
          return;
        }
        this.busy = true;
        this.error = null;
        io.emit('roles/saveRole', this.role, (result) => {
          if(result.error) {
            this.error = result.error;
          } else {
            this.$router.push({name: 'roles'});
          }
          this.busy = false;
        });
      },
      async confirmDelete() {
        let confirmation = await this.$bvModal.msgBoxConfirm(
          `Delete role "${this.role.name}"?  All permission assignments will be lost.`,
          { okVariant: 'danger', okTitle: 'Delete', 
            title: 'Confirm Deletion'});
        if(confirmation) {
          this.delete();
        }
      },
      delete() {
        this.busy = true;
        this.error = null;
        io.emit('roles/deleteRole', this.role.name, (result) => {
          if(result.error) {
            this.error = result.error;
          } else {
            this.$router.push({name: 'roles'});
          }
          this.busy = false;
        });
      },
      checkName: debounce(function() {
        io.emit('roles/checkRoleName', this.role.name, (result) => {
          console.log(result);
          this.nameValid = result.ok === true;
        });
      }, 300),
    },

    watch: {
      '$route'() {
        this.routeChanged();
      },
      'role.name'(name) {
        this.nameValid = null;
        if(this.isNew) {
          this.checkName();
        }
      },
    },

    data() {
      return {
        role: null,
        error: null,
        busy: false,
        nameValid: null,
      };
    },
  }
</script>

<style scoped>
  #input-name {
    max-width: 400px;
  }

  .permission-options {
    margin-bottom: 1em;
    list-style-type: none;
  }

  .target-options {
    list-style-type: none;
  }

  .permission-options li {
    margin-bottom: 1em;
  }

  .description {
    font-size: 9pt;
  }

  .buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .buttons button {
    margin-right: 1em;
  }

</style>
