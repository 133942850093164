import io from '@/socket-instance';

export default {
  namespaced: true,

  state: {
    roles: null,
    loading: false,
    error: null,
    permissions: null,
  },

  mutations: {
    START_LOADING_ROLES(state) {
      state.loading = true;
      state.error = null;
    },
    SET_ROLES(state, roles) {
      state.roles = roles;
      state.loading = false;
      state.error = null;
    },
    SET_ERROR(state, error) {
      state.loading = false;
      state.error = error;
    },
    SET_PERMISSIONS(state, permissions) {
      state.permissions = permissions;
    }
  },

  actions: {
    loadRoles({commit}) {
      commit('START_LOADING_ROLES');
      io.emit('roles/listRoles', (result) => {
        if(result.error) {
          commit('SET_ERROR', result.error);
        } else {
          commit('SET_ROLES', result.roles);
        }
      });
    },
    deleteRole({commit, dispatch}, name) {
      io.emit('roles/deleteRole', name, (result) => {
        if(result.error) {
          commit('SET_ERROR', result.error);
        } else {
          dispatch('loadRoles');
        }
      });
    },
    loadPermissions({commit}) {
      io.emit('roles/listPermissions', (result) => {
        commit('SET_PERMISSIONS', result.permissions);
      });
    },
  },
};
