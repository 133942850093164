export default {
  namespaced: true,

  state: {
    token: null,
  },

  mutations: {
    SET_TOKEN(state, token) {
      state.token = token;
    },
  }
};
