<template>
  <div class="create-api-key-container">
    <b-button variant="primary" v-b-modal.create-api-key-modal @click="start">
      <fa-icon icon="plus"/> Create API Key
    </b-button>
    <b-modal id="create-api-key-modal" title="Create API Key" v-model="showModal">
      <b-form>
        <b-form-group label-for="input-comment" label="Comment" label-cols-md="3"
          description="Name this API key to help identify how it is used">
          <b-form-input id="input-comment" v-model="comment"/>
        </b-form-group>

        <b-form-group label-for="select-expiration" label="Expiration" label-cols-md="3">
          <b-form-select :options="expOptions" v-model="expiration"/>
        </b-form-group>
      </b-form>
      

      <b-alert variant="danger" class="mt-3" v-if="error" show>{{error}}</b-alert>

      <template v-slot:modal-footer>
        <b-spinner v-if="busy"/>
        <b-button variant="secondary" @click="showModal=false">Cancel</b-button>
        <b-button variant="primary" :disabled="!inputValid || busy"
          @click="create">
          Create Key
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
  import io from '@/socket-instance';

  export default {
    name: 'CreateApiKeyModal',
    props: ['user'],

    components: {

    },

    mounted() {

    },

    computed: {
      inputValid() {
        return this.comment.length > 0;
      }
    },

    methods: {
      start() {
        this.comment = '';
        this.expiration = null;
      },

      create() {
        const args = {
          user: this.user,
          comment: this.comment,
          expiration: this.expiration,
        };

        this.busy = true;
        this.error = null;

        io.emit('users/createUserApiKey', args, (result) => {
          if(result.error) {
            this.error = result.error;
          } else {
            this.showModal = false;
            this.$emit('created');
          }
          this.busy = false;
        });
      },
    },

    data() {
      return {
        comment: '',
        expiration: null,

        showModal: false,
        busy: false,
        error: null,

        expOptions: [
          {value: null, text: 'Never'},
          {value: '1d', text: '1 day'},
          {value: '7d', text: '7 days'},
          {value: '1m', text: '1 month'},
          {value: '1y', text: '1 year'},
        ]
      };
    },
  }
</script>

<style scoped>

</style>
