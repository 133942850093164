import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

import BootstrapVue from 'bootstrap-vue';
import VueSocketIO from 'vue-socket.io-extended';
import io from './socket-instance';
import './icons';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

Vue.config.productionTip = false;
Vue.use(BootstrapVue);
Vue.use(VueSocketIO, io, {store});

import Truncated from '@/components/Truncated';
Vue.component('trunc', Truncated);

store.dispatch('events/loadEventStates');
store.dispatch('events/loadTicketTypes');

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
