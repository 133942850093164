import io from '@/socket-instance';

export default {
  namespaced: true,

  state: {
    items: null,
    error: null,
    query: null,
    pageBusy: false,
    count: 0,
    pagesize: 25,
    page: 1,
  },

  getters: {
    pages(state) {
      if(state.count == 0) return 1;
      return Math.ceil(state.count / state.pagesize);
    }
  },

  mutations: {
    SET_ERROR(state, error) {
      state.items = null;
      state.error = error;
    },
    START_QUERY(state, query) {
      state.items = null;
      state.error = null;
      state.query = query;
      state.count = 0;
      state.page = 1;
    },
    QUERY_RESULT(state, {items, count}) {
      state.items = items;
      state.error = null;
      state.count = parseInt(count);
    },
    START_LOAD_PAGE(state, page) {
      state.page = page;
      state.pageBusy = true;
      state.error = null;
    },
    LOAD_PAGE_RESULT(state, items) {
      state.items = items;
      state.pageBusy = false;
      state.error = null;
    },
    INVALIDATE_RESULTS(state) {
      state.items = null;
    },
  },

  actions: {
    listBrands({commit, state}, query) {
      commit('START_QUERY', query);

      const args = {
        query: state.query,
        limit: state.pagesize,
        offset: (state.page-1) * state.pagesize,
      };

      io.emit('brands/listBrands', args, (result) => {
        if(result.error) {
          commit('SET_ERROR', result.error);
        } else {
          commit('QUERY_RESULT', {
            items: result.brands,
            count: result.count
          });
        }
      });
    },

    loadPage({commit, state, getters}, page) {
      if(page > getters.pages) {
        page = getters.page;
      }

      commit('START_LOAD_PAGE', page);

      const args = {
        query: state.query,
        limit: state.pagesize,
        offset: (state.page-1) * state.pagesize,
      };

      io.emit('brands/listBrands', args, (result) => {
        if(result.error) {
          commit('SET_ERROR', result.error);
        } else {
          commit('LOAD_PAGE_RESULT', result.brands);
        }
      });
    }
  },
};
