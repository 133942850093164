import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';

import auth from './auth';
import local from './local';
import roles from './roles';
import users from './users';
import brands from './brands';
import brand from './brand';
import events from './events';
import event from './event';
import streams from './streams';
import stream from './stream';

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
  stroage: window.localStorage,
  reducer: (state) => ({ local: state.local }),
});

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    auth,
    local,
    roles,
    users,
    brands,
    brand,
    events,
    event,
    streams,
    stream,
  },
  plugins: [
    vuexLocal.plugin,
  ]
})
