<template>
  <b-nav-item-dropdown right>
    <template v-slot:button-content>
      <fa-icon icon="user"/> {{user.first_name}} {{user.last_name}}
    </template>
    <b-dropdown-item @click="logout">Sign Out</b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
  import {mapState, mapActions} from 'vuex';

  export default {
    name: 'UserDropdown',

    computed: {
      ...mapState('auth', ['user']),
    },

    methods: {
      ...mapActions('auth', ['authLogout']),
      logout() {
        this.authLogout();
        this.$router.push({name: 'home'});
      }
    },
  }
</script>

<style scoped>

</style>
