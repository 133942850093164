<template>
  <paginated-list title="Brands" :error="error" :items="items" :count="count"
    :page-busy="pageBusy" :pagesize="pagesize" :page="page"
    empty-placeholder="No brands found."
    @invalidate="INVALIDATE_RESULTS" @search="listBrands" @loadPage="loadPage">

    <template v-slot:buttons>
      <b-button variant="primary" v-if="canCreate"
        to="/admin/brands/create">
        <fa-icon icon="plus"/> Create Brand
      </b-button>
    </template>

    <template v-slot:table>
      <b-table :items="items" :fields="fields">
        <template v-slot:cell(title)="data">
          <trunc>
            <router-link :to="`/admin/brands/${data.item.slug}`">
              <strong>{{data.value}}</strong>
            </router-link>
          </trunc>
        </template>
      </b-table>
    </template>

  </paginated-list>
</template>

<script>
  import {mapState, mapActions, mapMutations} from 'vuex';

  import PaginatedList from '@/components/PaginatedList';

  export default {
    name: 'Brands',
    components: {
      PaginatedList
    },

    mounted() {
      this.listBrands();
    },

    computed: {
      ...mapState('brands', [
        'items',
        'error',
        'pageBusy',
        'count',
        'pagesize',
        'page',
      ]),
      canCreate() {
        return this.$store.getters['auth/hasGlobalPermission']('createArtistBrands');
      },
    },

    methods: {
      ...mapActions('brands', [
        'listBrands',
        'loadPage',
      ]),
      ...mapMutations('brands', [
        'INVALIDATE_RESULTS',
      ]),
    },

    data() {
      return {
        fields: [
          { key: 'title', label: 'Brand' },
          { key: 'slug', label: 'Slug' },
        ]
      };
    },
  }
</script>

<style scoped>

</style>
