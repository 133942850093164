<template>
  <div class="assign-role-container">
    <b-button variant="primary" v-b-modal.assign-role-modal @click="start">
      <fa-icon icon="plus"/> Assign Role
    </b-button>
    <b-modal id="assign-role-modal" title="Assign Role" v-model="showModal">
      <b-form>
        <b-form-group label-for="select-role" label="Role" label-cols-md="3"
          :description="roleDescription">
          <b-form-select :options="roleNames" v-model="role" id="select-role"/>
        </b-form-group>

        <b-form-group label-for="select-scope" label="Scope" label-cols-md="3">
          <object-finder v-model="targetObject" :disabled="global"/>
        </b-form-group>

        <b-row>
          <b-col offset-md="3">
            <b-form-checkbox id="global-checkbox" v-model="global" :value="true">
              Assign globally
            </b-form-checkbox>
          </b-col>
        </b-row>
      </b-form>

      <b-alert variant="danger" class="mt-3" v-if="error" show>{{error}}</b-alert>

      <template v-slot:modal-footer>
        <b-spinner v-if="busy"/>
        <b-button variant="secondary" @click="showModal=false">Cancel</b-button>
        <b-button variant="primary" :disabled="!inputValid || busy"
          @click="assign">
          Assign
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
  import io from '@/socket-instance';
  import {mapState, mapActions} from 'vuex';

  import ObjectFinder from '@/components/ObjectFinder';

  export default {
    name: 'AssignRoleModal',
    props: ['user'],

    components: {
      ObjectFinder,
    },

    mounted() {
      if(!this.roles) {
        this.loadRoles();
      }
    },

    computed: {
      ...mapState('roles', ['roles']),
      roleNames() {
        if(!this.roles) return [];
        let options = [];
        for(let role of this.roles) {
          options.push({
            text: role.name,
            value: role
          });
        }

        return options;
      },
      roleDescription() {
        return this.role? this.role.description : '';
      },
      target() {
        if(this.global) return 'global';
        return this.targetObject;
      },
      inputValid() {
        return this.role && this.target;
      }
    },

    methods: {
      ...mapActions('roles', ['loadRoles']),
      start() {
        this.role = null;
        this.targetObject = null;
        this.global = false;
        this.error = null;
        this.showModal = true;
        this.busy = false;
      },
      assign() {
        const args = {
          user: this.user,
          role: this.role.name,
          target: this.target,
        }
        this.busy = true;
        this.error = null;
        io.emit('users/assignRole', args, (result) => {
          if(result.error) {
            this.error = result.error;
          } else {
            this.showModal = false;
            this.$emit('assigned');
          }
          this.busy = false;
        });
      }
    },

    data() {
      return {
        role: null,
        targetObject: null,
        global: false,
        showModal: false,
        busy: false,
        error: null,
      };
    }
  }
</script>

<style scoped>
  .assign-role-container {
    display: inline-block;
  }
</style>
