<template>
  <div class="login">
  <b-container>
    <b-alert variant="danger" v-if="authError" show>{{authError}}</b-alert>
    <b-form @submit.prevent="login">
      <b-form-group id="email-group" label="E-mail:" label-for="email-input">
        <b-form-input id="email-input" v-model="email" type="email"/>
      </b-form-group>
      <b-form-group id="password-group" label="Password:" label-for="password-input">
        <b-form-input type="password" id="password-input" v-model="password"/>
      </b-form-group>
      <div class="buttonbox">
        <b-button type="submit" variant="primary" :disabled="authBusy">Login</b-button>
        <b-spinner v-if="authBusy" class="ml-3"/>
      </div>
    </b-form>
  </b-container>
  </div>
</template>

<script>
  import {mapState, mapActions} from 'vuex';

  export default {
    name: 'Login',

    computed: {
      ...mapState('auth', [
        'authError',
        'authBusy',
        'user',
      ]),
    },

    methods: {
      ...mapActions('auth', [
        'authLogin'
      ]),
      login() {
        this.authLogin({
          email: this.email,
          password: this.password
        });
      }
    },

    data() {
      return {
        email: '',
        password: '',
      }
    },
  }
</script>

<style scoped>
  .login {
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 48px;
  }

  .buttonbox {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
</style>
