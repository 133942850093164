<template>
  <div class="uuid-display">
    {{value}}
  </div>
</template>

<script>
  export default {
    name: 'UuidDisplay',
    props: ['value'],
  }
</script>

<style scoped>
  .uuid-display {
    color: gray;
    font-family: 'Monaco', 'Courier', fixed;
    font-size: 10pt;
    padding: 5px;
    border: 1px solid rgba(0,0,0,0.125);
    border-radius: 6px;
  }
</style>
