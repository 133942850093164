import io from '@/socket-instance';
import router from '@/router';
import uuidv4 from 'uuid/v4';

export default {
  namespaced: true,

  state: {
    brand: null,
    events: null,
    permissions: [],
    error: null,
    busy: false,
    isNew: false,
  },

  mutations: {
    START_LOADING(state) {
      state.brand = null;
      state.events = null;
      state.permissions = [];
      state.error = null;
      state.busy = false;
    },
    BRAND_LOADED(state, {brand, events, permissions}) {
      state.brand = brand;
      state.events = events;
      state.permissions = permissions;
      state.error = null
      state.busy = false;
      state.isNew = false;
    },
    START_SAVING(state) {
      state.busy = true;
      state.error = null;
    },
    SET_ERROR(state, error) {
      state.error = error;
      state.busy = false;
    },
    NEW_BRAND(state) {
      state.brand = {
        id: uuidv4(),
        slug: '',
        display_name: '',
        bio: '',
        image: '',
        merch: '',
        spotify: '',
        applemusic: '',
        venmo: '',
        cash_app: '',
        default_ticket_url: '',
      },
      state.events = null;
      state.busy = false;
      state.error = null;
      state.isNew = true;
      state.permissions = [];
    },
  },

  actions: {
    loadBrand({commit}, id) {
      io.emit('brands/loadBrand', id, (result) => {
        if(result.error) {
          commit('SET_ERROR', result.error);
        } else {
          commit('BRAND_LOADED', result);
        }
      });
    },
    createBrand({commit}, brand) {
      commit('START_SAVING');
      io.emit('brands/createBrand', brand, (result) => {
        if(result.error) {
          commit('SET_ERROR', result.error);
        } else {
          router.push({
            name: 'edit-brand',
            params: {slug: brand.slug, msg: "Artist brand created."},
          });
        }
      });
    },
    saveBrand({commit}, brand) {
      commit('START_SAVING');
      io.emit('brands/saveBrand', brand, (result) => {
        if(result.error) {
          commit('SET_ERROR', result.error);
        } else {
          router.push({
            name: 'brands',
          });
        }
      });
    },
    deleteBrand({commit}, brandId) {
      commit('START_SAVING');
      io.emit('brands/deleteBrand', brandId, (result) => {
        if(result.error) {
          commit('SET_ERROR', result.error);
        } else {
          router.push({
            name: 'brands',
          });
        }
      });
    },
    newBrand({commit}) {
      commit('NEW_BRAND');
    },
  },
};
