<template>
  <div class="create-stream-container">
    <b-button variant="primary" @click="start">
      <fa-icon icon="plus"/> Create Stream
    </b-button>
    <b-modal title="Create Stram" v-model="showModal">
      <b-form @submit.prevent="">
        <b-form-group label-for="input-slug" label="Slug" label-cols-md="3"
          :invalid-feedback="slugFeedback" :valid-feedback="slugFeedback">
          <b-input id="input-slug" v-model="form.slug" :state="slugValid" trim/>
        </b-form-group>
        <b-form-group label-for="select-provider" label="Provider" label-cols-md="3">
          <b-form-select id="select-provider" :options="providerOptions" 
            v-model="form.provider" v-if="providers"/>
          <b-spinner v-else/>
        </b-form-group>
        <b-form-group label-for="select-region" label="Region" label-cols-md="3">
          <b-spinner v-if="form.provider && !regions"/>
          <b-form-select id="select-region" :options="regions" 
            v-model="form.cloudRegion" v-else :disabled="!form.provider"/>
        </b-form-group>
      </b-form>

      <b-alert variant="danger" class="mt-3" v-if="error" show>{{error}}</b-alert>      

      <template v-slot:modal-footer>
        <b-spinner v-if="busy"/>
        <b-button variant="secondary" @click="showModal=false">Cancel</b-button>
        <b-button variant="primary" :disabled="!inputValid || busy"
          @click="createStream">
          Create Stream
        </b-button>
      </template>

    </b-modal>
  </div>
</template>

<script>
  import io from '@/socket-instance';
  import {debounce} from 'lodash';
  import {formatProviderName} from '@/common/streams';

  export default {
    name: 'CreateStreamModal',
    props: ['event'],

    methods: {
      start() {
        this.showModal = true;
        this.form = {
          slug: '',
          provider: null,
          cloudRegion: null,
          event_id: this.event,
        };
        this.slugValid = null;
        this.slugFeedback = null;
        this.listProviders();
        this.getRandomSlug(5);
      },
      checkSlug: debounce(function() {
        const args = {
          slug: this.form.slug,
        };
        io.emit('streams/checkSlug', args, (result) => {
          this.slugValid = result.ok;
          this.slugFeedback = result.feedback;
        });
      }, 300),
      getRandomSlug(l) {
        io.emit('streams/randomSlug', l, (result) => {
          if(!result.error) {
            this.form.slug = result;
          }
        });
      },
      listProviders() {
        this.providers = null;
        this.regions = null;
        io.emit('streams/listProviders', (result) => {
          if(!result.error) {
            this.providers = result.providers;
            if (result.providers.includes("elemental")) {
              this.form.provider = "elemental"
            }
          }
        });
      },
      listRegions(provider) {
        this.regions = null;
        io.emit('streams/listProviderRegions', provider, (result) => {
          if(!result.error) {
            this.regions = result.regions;
            if (result.regions.includes("us-east-1")) {
              this.form.cloudRegion = "us-east-1"
            }
          }
        });
      },
      createStream() {
        this.busy = true;
        this.error = null;
        io.emit('streams/createStream', this.form, (result) => {
          if(result.error) {
            this.error = result.error;
          } else {
            this.showModal = false;
          }
          this.busy = false;
        });
      },
    },

    computed: {
      providerOptions() {
        if(!this.providers) return null;
        return this.providers.map(x => ({ value: x, 
          text: formatProviderName(x) }));
      },
      inputValid() {
        return this.slugValid && this.form.provider;
      },
    },

    data() {
      return {
        showModal: false,
        form: {},
        providers: null,
        regions: null,
        slugValid: null,
        slugFeedback: null,
        busy: false,
        error: null,
      };
    },

    watch: {
      'form.slug'(slug) {
        this.slugValid = null;
        if(slug !== undefined && slug.length > 0) {
          this.checkSlug();
        }
      },
      'form.provider'(provider) {
        this.regions = null;
        this.form.cloudRegion = null;
        if(provider) {
          this.listRegions(provider);
        }
      },
    },
  }
</script>

<style scoped>
  .create-stream-container {
    display: inline-block;
  }
</style>
