import moment from 'moment';

export function formatDateTime(dt) {
  if(!dt) return '';
  return moment(dt).format('D MMM YYYY [at] h:mm A');
}

export function formatMinutes(m) {
  if(!m) return '';
  return `${m} minutes`;
}
