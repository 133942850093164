<template>
  <b-container>
    <h1>{{title}}</h1>

    <b-alert v-if="error && !event" variant="danger" show>{{error}}</b-alert>

    <loading-spinner v-if="!event && !error"/>
    <div v-else-if="event">
      <b-form @input="touch" @submit.prevent="">
        <b-form-group label-for="input-displayname" label-cols-md="3"
          label="Event Title">
          <b-input id="input-displayname" v-model="form.display_name"
            :disabled="!editable"/>
        </b-form-group>
        <b-form-group label-for="input-slug" label-cols-md="3" label="Slug" 
          :valid-feedback="slugFeedback" :invalid-feedback="slugFeedback">
          <b-input id="input-slug" v-model="form.slug"
            :disabled="!(editable && isNew)" :state="slugValid"/>
        </b-form-group>
        <b-form-group label-cols-md="3"
          label="UUID">
          <uuid-display :value="form.id"/>
        </b-form-group>
        <b-form-group label-for="input-brand" label-cols-md="3"
          label="Artist Brand">
          <object-finder type="artist_brand" v-model="form.artist_brand_id"
            :disabled="!canChangeBrand"/>
        </b-form-group>
        <b-form-group label-for="input-ticketurl" label-cols-md="3"
          label="Ticket URL"
          description="#TICKET# will be replaced with the ticket token">
          <b-input id="input-ticketurl" v-model="form.ticket_url"
            :disabled="!editable"/>
        </b-form-group>
        <b-form-group label-for="input-description" label-cols-md="3"
          label="Description">
          <b-form-textarea id="input-description" v-model="form.description"
            :disabled="!editable"/>
        </b-form-group>
       
        <!--
        <h3>Event Timing</h3>
        <b-row>
          <b-col md="6">
            <b-form-group label-for="input-starttime" label-cols-md="3"
              label="Start Time">
              <b-form-input type="datetime-local" id="input-starttime" v-model="form.start_time"
                :disabled="!editable"/>
            </b-form-group>
          </b-col>
        </b-row>
        -->

        <b-alert show v-if="error" variant="danger">{{error}}</b-alert>
        <b-alert show v-else-if="alert">{{alert}}</b-alert>


        <div class="button-box mb-4">
          <b-button v-if="isNew" :disabled="!canCreate || !isValid || busy"
            variant="primary" @click="createEvent(form)">
            <fa-icon icon="plus"/> Create Event
          </b-button>
          <b-button v-else :disabled="!dirty || busy || !canModify"
            variant="primary" @click="saveEvent(form)">
            <fa-icon icon="save"/> Save Event
          </b-button>
          <b-button v-if="canDelete && !isNew" variant="danger"
            @click="confirmDeleteEvent">
            <fa-icon icon="trash-alt"/> Delete Event
          </b-button>
          <b-spinner v-if="busy"/>

        </div>
      </b-form>

      <b-tabs content-class="mt-3" v-if="!isNew">
        <b-tab title="Venue" >
          <b-form-group label-for="event-state" label-cols-md="3" label="Event Status">
            <b-row>
            <b-col cols="6">
              <b-form-select id="event-state" :options="eventStateOptions"
                :value="event.state" :disabled="stateTransitioning || !canStartStop"
                @input="setState"/>
            </b-col>
            <b-col cols="1">
              <b-spinner v-if="stateTransitioning"/>
            </b-col>
            </b-row>
          </b-form-group>
        </b-tab>

        <b-tab title="Interactions">
          <interaction-viewer/>
        </b-tab>

        <b-tab title="Accounting">
          <b-container>
            <b-row>
              <b-col>
                <h3>Activity Log</h3>
                <b-spinner v-if="!activityLog"/>
                <div v-else-if="activityLog.length == 0">
                  <em>This event has not yet been active.</em>
                </div>
                <event-log-table v-else :log="activityLog"/>
              </b-col>

            </b-row>
          </b-container>
        </b-tab>

        <b-tab title="Ticketing">
          <b-container>
            <b-row>

              <b-col md="4">
                <h3>Ticket Stats</h3>
                <b-list-group style="max-width: 300px">
                  <b-list-group-item class="d-flex justify-content-between align-items-center">
                    Tickets Created
                    <b-badge variant="secondary">{{event.tickets_created}}</b-badge>
                  </b-list-group-item>
                  <b-list-group-item class="d-flex justify-content-between align-items-center">
                    Tickets Revoked
                    <b-badge variant="danger">{{event.tickets_revoked}}</b-badge>
                  </b-list-group-item>
                  <b-list-group-item class="d-flex justify-content-between align-items-center"
                    variant="primary">
                    Total Valid Tickets
                    <b-badge variant="primary">{{validTickets}}</b-badge>
                  </b-list-group-item>
                </b-list-group>
              </b-col>

              <b-col md="8" v-if="canCreateTickets">
                <h3>Issue Tickets</h3>
                <b-form-group label-for="ticket-type" label-cols-md="3"
                  label="Ticket Type">
                  <b-form-select id="ticket-type"
                    :options="ticketTypeOptions"
                    v-model="issueTickets.ticketType"/>
                </b-form-group>
                <b-form-group label-cols-md="3" v-if="canBypassAccounting">
                  <b-form-checkbox id="bypass-accounting"
                    v-model="issueTickets.bypassAccounting">
                    Exclude from accounting metrics
                  </b-form-checkbox>
                </b-form-group>
                <b-alert variant="danger" show
                  v-if="issueTickets.error">{{issueTickets.error}}</b-alert>
                <div class="d-flex flex-row align-items-center">
                  <b-button class="mr-3" :disabled="issueTickets.creating"
                    @click="createAndShowTicket">
                    <fa-icon icon="ticket-alt"/> Create Ticket
                  </b-button>
                  <b-spinner v-if="issueTickets.creating"/>
                </div>
              </b-col>
            </b-row>
          </b-container>

          <b-modal id="ticket-modal" v-model="issueTickets.ticket !== null"
            @hide="issueTickets.ticket = null">
            
            <div class="ticket-display">{{ticketLink}}</div>

            <template #modal-title>
              <fa-icon icon="ticket-alt" class="mr-1"/> Ticket
            </template>

            <template #modal-footer="{ok}">
              <b-button @click="copyTicket">
                <fa-icon icon="clipboard"/> Copy Ticket
              </b-button>
              <b-button :href="ticketLink" target="_blank" class="mr-auto">
                <fa-icon icon="link"/> Use Ticket
              </b-button>
              <b-button @click="ok"><fa-icon icon="times"/> Close</b-button>
            </template>

          </b-modal>
        </b-tab>

        <b-tab title="Streams">
          <b-alert v-if="streamError" variant="danger" dismissible
            v-model="streamErrorVisible">{{streamError}}</b-alert>
          <b-table :items="streams" :fields="streamFields" v-if="streams.length > 0">
            <template v-slot:cell(slug)="data">
              <router-link :to="`/admin/streams/${data.item.slug}`">
                {{data.value}}
                <b-badge v-if="data.item.active" variant="primary" class="ml-1">
                  Active
                </b-badge>
              </router-link>
            </template>
            <template v-slot:cell(state)="data">
              <b-badge :variant="streamStateBadgeVariant(data.value)">
                {{formatStreamStateBadge(data.value)}}
              </b-badge>
            </template>
            <template v-slot:cell(actions)="data">
              <a href="#" class="action-btn" 
                @click.prevent="streamAction('activate', data.item.id)"
                v-if="canModify && !data.item.active"
                v-b-tooltip title="Activate">
                <fa-icon icon="star"/>
              </a>
              <a href="#" class="action-btn" @click.prevent=""
                @click.prevent="streamAction('start', data.item.id)"
                v-if="canStartStopStreams && data.item.state=='READY'"
                v-b-tooltip title="Start">
                <fa-icon icon="running"/>
              </a>
              <a href="#" class="action-btn" @click.prevent=""
                @click.prevent="streamAction('stop', data.item.id)"
                v-if="canStartStopStreams && data.item.state=='RUNNING'"
                v-b-tooltip title="Stop">
                <fa-icon icon="hand-paper"/>
              </a>
              <a href="#" class="action-btn" @click.prevent=""
                @click.prevent="streamAction('reset', data.item.id)"
                v-if="canStartStopStreams && data.item.state=='FINISHED'"
                v-b-tooltip title="Reset">
                <fa-icon icon="redo"/>
              </a>
              <a href="#" class="action-btn" @click.prevent=""
                @click.prevent="confirmDeleteStream(data.item)"
                v-if="canDeleteStream(data.item)"
                v-b-tooltip title="Delete">
                <fa-icon icon="times"/>
              </a>
            </template>
          </b-table>
          <div v-else>
            <em>This event has no streams.</em>
          </div>
          <div class="mt-3">
            <create-stream-modal :event="event.id" v-if="canCreateStreams"/>
          </div>
        </b-tab>

        <b-tab title="Configuration">
          <event-config-editor :readOnly="!canModify"/>
        </b-tab>

      </b-tabs>
    </div>


  </b-container>
</template>

<script>
  import {mapState, mapActions, mapMutations} from 'vuex';
  import {debounce} from 'lodash';
  import {streamStateBadgeVariant, formatStreamStateBadge, formatProviderName}
    from '@/common/streams';
  import io from '@/socket-instance';

  import LoadingSpinner from '@/components/LoadingSpinner';
  import UuidDisplay from '@/components/UuidDisplay';
  import ObjectFinder from '@/components/ObjectFinder';
  import CreateStreamModal from '@/components/CreateStreamModal';
  import EventLogTable from '@/components/EventLogTable';
  import EventConfigEditor from '@/components/EventConfigEditor';
  import InteractionViewer from '@/components/InteractionViewer';

  export default {
    name: 'EditEvent',

    components: {
      LoadingSpinner,
      UuidDisplay,
      ObjectFinder,
      CreateStreamModal,
      EventLogTable,
      EventConfigEditor,
      InteractionViewer,
    },

    mounted() {
      this.routeChanged();
    },

    computed: {
      ...mapState('event', [
        'event',
        'streams',
        'permissions',
        'busy',
        'error',
        'isNew',
        'activityLog',
      ]),
      ...mapState('events', ['eventStates', 'ticketTypes']),
      title() {
        if(this.isNew) {
          return 'New Event';
        } else {
          return 'Event Details';
        }
      },
      editable() {
        return (this.isNew && this.canCreate) || this.canModify;
      },
      canChangeBrand() {
        return this.hasPermission('moveEvents') || (this.isNew && !this.lockBrand);
      },
      canStartStopStreams() {
        return this.hasPermission('startStopStreams');
      },
      canCreateStreams() {
        return this.hasPermission('createStreams');
      },
      canCreateTickets() {
        return this.hasPermission('createTickets');
      },
      canBypassAccounting() {
        return this.hasPermission('bypassAccounting');
      },
      canDeleteStreams() {
        return this.hasPermission('deleteStreams');
      },
      canStartStop() {
        return this.hasPermission('startStopEvents');
      },
      canModify() {
        return this.hasPermission('modifyEvents');
      },
      canCreate() {
        return this.$store.getters['auth/hasGlobalPermission']('createEvents') ||
          this.lockBrand;
      },
      isValid() {
        return this.slugValid && this.form.display_name && this.form.artist_brand_id;
      },
      canDelete() {
        return this.hasPermission('deleteEvents');
      },
      validTickets() {
        const valid = this.event.tickets_created - this.event.tickets_revoked;
        if(!valid || valid < 0) return 0;
        return valid;
      },

      

      eventStateOptions() {
        const options = [];
        for(const key of Object.keys(this.eventStates)) {
          options.push({value: key, text: this.eventStates[key].title});
        }
        return options;
      },

      ticketTypeOptions() {
        const options = [];
        for(const key of Object.keys(this.ticketTypes)) {
          options.push({value: key, text: this.ticketTypes[key].title});
        }
        return options;
      },

      ticketLink() {
        if(!this.issueTickets.ticket) return '';
        if(this.event.ticket_url.includes('#TICKET#')) {
          return this.event.ticket_url.replace('#TICKET#', this.issueTickets.ticket.token);
        } else {
          return this.issueTickets.ticket.token;
        }
      }


    },

    methods: {
      ...mapActions('event', ['loadEvent', 'newEvent', 'createEvent',
        'saveEvent', 'deleteEvent', 'setEventState', 'createTicket']),
      streamStateBadgeVariant,
      formatStreamStateBadge,
      routeChanged() {
        if(this.$route.params.slug == 'create') {
          this.newEvent({
            brandId: this.$route.params.brandId,
            ticketUrl: this.$route.params.ticketUrl
          });
        } else {
          this.loadEvent(this.$route.params.slug);
        }
        if(this.$route.query.msg) {
          this.alert = this.$route.query.msg;
        } else {
          this.alert = null;
        }
        this.lockBrand = this.$route.params.brandId;
        this.slugValid = null;
        this.slugFeedback = null;
        this.stateTransitioning = false;
      },
      touch() {
        this.dirty = true;
        this.alert = null;
      },
      suggestSlugFromName: debounce( function() {
        const name = this.form.display_name; 
        io.emit('events/suggestSlugFromName', name, (result) => {
          if(!result.error) {
            this.form.slug = result;
          }
        });
      }),
      hasPermission(permission) {
        return this.permissions.includes(permission);
      },
      canDeleteStream(stream) {
        return this.canDeleteStreams && (
          stream.state == 'READY'
          || stream.state == 'FINISHED'
          || stream.state == 'FAILED'
        );
      },
      streamAction(action, id) {
        io.emit(`streams/${action}Stream`, id, (result) => {
          if(result.error) {
            this.showStreamError(result.error);
          }
        });
      },
      showStreamError(text) {
        this.streamError = text;
        this.streamErrorVisible = true;
      },
      async confirmDeleteStream(item) {
        let confirmation = await this.$bvModal.msgBoxConfirm(
          `Delete stream "${item.slug}"?`,
          { okVariant: 'danger', okTitle: 'Delete', 
            title: 'Confirm Deletion'});
        if(confirmation) {
          this.streamAction('delete', item.id);
        }
      },
      async confirmDeleteEvent() {
        let confirmation = await this.$bvModal.msgBoxConfirm(
          `Delete event "${this.event.title}"?`,
          { okVariant: 'danger', okTitle: 'Delete', 
            title: 'Confirm Deletion'});
        if(confirmation) {
          this.deleteEvent(this.form.id);
        }
      },
      checkSlug: debounce(function() {
        const args = {
          slug: this.form.slug,
          id: this.event.id,
        };
        io.emit('events/checkSlug', args, (result) => {
          this.slugValid = result.ok;
          this.slugFeedback = result.feedback;
        });
      }, 300),

      async setState(state) {
        this.stateTransitioning = true;
        console.log('setState', state);
        await this.setEventState({
          eventId: this.event.id,
          state
        });
        this.stateTransitioning = false;
      },

      async createAndShowTicket() {
        this.issueTickets.creating = true;
        this.issueTickets.error = null;
        try {
          const ticket = await this.createTicket({
            eventId: this.event.id,
            options: {
              bypassAccounting: this.issueTickets.bypassAccounting,
              claims: this.ticketTypes[this.issueTickets.ticketType].claims
            }
          });
          this.issueTickets.ticket = ticket;
          //console.log(ticket);
        } catch(e) {
          this.issueTickets.error = e.toString();
        }

        this.issueTickets.creating = false;
      },

      async copyTicket() {
        await navigator.clipboard.writeText(this.ticketLink);
      }
    },

    watch: {
      '$route'() {
        this.routeChanged();
      },
      event(ev) {
        this.form = Object.assign({}, ev);
      },
      'form.slug'(slug) {
        this.slugValid = null;
        if(slug !== undefined && slug.length > 0) {
          this.checkSlug();
        }
      },
      'form.display_name'(name) {
        if(name!==undefined && name.length > 0 && this.isNew) {
          this.suggestSlugFromName();
        }
      }
    },

    data() {
      return {
        form: {  },
        dirty: false,
        streamFields: [
          { key: 'slug', label: 'Stream' },
          { key: 'provider', label: 'Video Provider', 
            formatter: formatProviderName },
          { key: 'state', label: 'Status' },
          { key: 'actions', label: 'Actions' },
        ],
        streamError: null,
        streamErrorVisible: false,
        slugValid: null,
        slugFeedback: null,
        alert: null,
        lockBrand: null,
        stateTransitioning: false,

        issueTickets: {
          ticketType: 'standard',
          bypassAccounting: false,
          creating: false,
          error: null,
          ticket: null,
        }
      };
    },
  }
</script>

<style scoped>
  .action-btn {
    color: gray;
    margin-right: 0.5em;
  }

  .action-btn:hover {
    color: black;
  }

  .ticket-display {
    font-family: monospace;
    word-break: break-all;
  }
</style>
