<template>
  <b-container>
    <h1>Users</h1>

    <b-alert variant="danger" show v-if="error">{{error}}</b-alert>


    <div class="header-box mb-3">
      <b-button v-if="canCreate" variant="primary"
        to="/admin/users/create">
        <fa-icon icon="plus"/> Create User
      </b-button>
      <b-form inline class="mb-3">
        <b-input id="search-input" type="search" v-model="search" placeholder="Search Users"/>
      </b-form>
    </div>
    <loading-spinner v-if="!users"/>
    <div v-else-if="count == 0">
      <em>No results.</em>
    </div>
    <div v-else>
      <b-table :items="users" :fields="fields" id="users-table">
        <template v-slot:cell(name)="data">
          <router-link :to="`/admin/users/${data.item.slug}`" 
            :class="{superadmin: data.item.is_super}">
            {{data.item.first_name}} {{data.item.last_name}}
          </router-link>
        </template>
      </b-table>
      <div class="pagebox">
        <b-pagination v-if="count > pagesize" v-model="page" :total-rows="count" 
          :per-page="pagesize" aria-controls="users-table" class="mr-2"/>
        <b-spinner v-if="pageBusy"/>
      </div>
    </div>
    <div class="button-box">
      <b-button v-if="canCreate && count > 0" variant="primary"
        to="/admin/users/create">
        <fa-icon icon="plus"/> Create User
      </b-button>
    </div>
  </b-container>
</template>

<script>
  import {mapState, mapGetters, mapActions, mapMutations} from 'vuex';

  import LoadingSpinner from '@/components/LoadingSpinner';
  import {debounce} from 'lodash';

  export default {
    name: 'Users',

    components: {
      LoadingSpinner,
    },

    mounted() {
      this.listUsers();
    },

    computed: {
      ...mapState('users', [
        'users',
        'error',
        'count',
        'pagesize',
        'pageBusy',
      ]),
      canCreate() {
        return this.$store.getters['auth/hasGlobalPermission']('createUsers');
      },
      page: {
        get() {
          return this.$store.state.users.page;
        },
        set(v) {
          this.loadPage(v);
        }
      }
    },

    methods: {
      ...mapActions('users', [
        'listUsers',
        'loadPage',
      ]),
      ...mapMutations('users', ['INVALIDATE_RESULTS']),
      performSearch: debounce(function() {
        this.listUsers(this.search.length > 0 ? this.search : null);
      }, 500),
    },

    data() {
      return {
        search: '',
        fields: [
          'name',
          {
            key: 'slug',
            label: 'Username',
          },
          {
            key: 'email',
            label: 'E-mail',
          }
        ],
      }
    },

    watch: {
      search() {
        this.INVALIDATE_RESULTS();
        this.performSearch();
      },
    },
  }
</script>

<style scoped>
  .pagebox {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .header-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .superadmin {
    font-weight: bold;
    color: red;
  }
</style>
