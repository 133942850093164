import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import Roles from '@/views/Roles';
import EditRole from '@/views/EditRole';
import Users from '@/views/Users';
import EditUser from '@/views/EditUser';
import Brands from '@/views/Brands';
import EditBrand from '@/views/EditBrand';
import Events from '@/views/Events';
import EditEvent from '@/views/EditEvent';
import Streams from '@/views/Streams';
import StreamDetails from '@/views/StreamDetails';
import WhatCan from '@/views/WhatCan';
import InvalidRoute from '@/views/InvalidRoute';

Vue.use(VueRouter);

  const routes = [
  {
    path: '/admin',
    name: 'home',
    redirect: '/admin/brands',
    //component: Home
  },
  {
    path: '/admin/roles',
    name: 'roles',
    component: Roles,
  },
  {
    path: '/admin/roles/:name',
    name: 'edit-role',
    component: EditRole,
  },
  {
    path: '/admin/users',
    name: 'users',
    component: Users,
  },
  {
    path: '/admin/users/:slug',
    name: 'edit-user',
    component: EditUser,
  },
  {
    path: '/admin/brands',
    name: 'brands',
    component: Brands,
  },
  {
    path: '/admin/brands/:slug',
    name: 'edit-brand',
    component: EditBrand,
  },
  {
    path: '/admin/events',
    name: 'events',
    component: Events,
  },
  {
    path: '/admin/events/:slug',
    name: 'edit-event',
    component: EditEvent,
  },
  {
    path: '/admin/streams',
    name: 'streams',
    component: Streams,
  },
  {
    path: '/admin/streams/:slug',
    name: 'stream-details',
    component: StreamDetails,
  },
  {
    path: '/admin/what-can',
    name: 'what-can',
    component: WhatCan,
  },
  {
    path: '/',
    redirect: '/admin',
  },
  {
    path: '*',
    name: 'invalid-route',
    component: InvalidRoute,
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  linkActiveClass: 'active',
})

export default router
