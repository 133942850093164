<template>
  <b-container>
    <h1>{{title}}</h1>

    <b-alert variant="danger" v-if="error" show>{{error}}</b-alert>

    <loading-spinner v-if="!user && !error"/>
    <template v-else-if="user">
      <b-form @input="touch">
        <h3>Personal Details</h3>
        <b-form-group label-for="input-firstname" label-cols-md="3"
          label="First Name" trim>
          <b-input id="input-firstname" v-model="user.first_name" :disabled="!editable"/>
        </b-form-group>
        <b-form-group label-for="input-lastname" label-cols-md="3"
          label="Last Name" trim>
          <b-input id="input-lastname" v-model="user.last_name" :disabled="!editable"/>
        </b-form-group>
        <b-form-group label-for="input-email" label-cols-md="3"
          label="E-mail">
          <b-input id="input-email" type="email" v-model="user.email" :disabled="!editable"/>
        </b-form-group>
        <b-form-group label-for="input-phone" label-cols-md="3"
          label="Telephone">
          <b-input id="input-phone" type="tel" v-model="user.phone" :disabled="!editable"/>
        </b-form-group>

        <h3>Login Info</h3>
        <b-form-group label-cols-md="3"
          label="UUID">
          <uuid-display :value="user.id"/>
        </b-form-group>
        <b-form-group label-for="input-slug" label-cols-md="3"
          :invalid-feedback="slugFeedback" :valid-feedback="slugFeedback"
          label="Username/Slug" trim>
          <b-input id="input-slug" v-model="user.slug" :disabled="!editable"
            :state="slugValid"/>
        </b-form-group>
        <b-form-group label-for="input-password" label-cols-md="3"
          :label="passwordLabel">
          <b-input id="input-password" type="password" v-model="user.password" 
            :disabled="!editable"/>
        </b-form-group>
        <b-form-group label-cols-md="3"
          label="Administrator">
          <b-form-checkbox :enabled="isSuper" v-model="user.is_super" :disabled="!editable">
            Is super administrator
          </b-form-checkbox>
        </b-form-group>
      </b-form>

      <b-alert show v-if="alert">{{alert}}</b-alert>

      <div class="button-box mb-3">
        <b-button v-if="isNew && canCreate" @click="createUser" variant="primary"
          :disabled="!dirty || busy">
          <fa-icon icon="plus"/> Create User
        </b-button>
        <b-button v-if="!isNew && canManage" @click="saveUser" variant="primary"
          :disabled="!dirty || busy">
          <fa-icon icon="save"/> Save User
        </b-button>
        <b-button v-if="!isNew && canDelete" @click="confirmDeleteUser" variant="danger"
          :disabled="busy">
          <fa-icon icon="trash-alt"/> Delete User
        </b-button>
        <b-spinner v-if="busy"/>
      </div>
    </template>

    <div class="roles mb-5" v-if="!isNew && user">
      <h3>User Roles</h3>
      <user-role-table :user="user.id"/>
    </div>

    <div class="apiKeys" v-if="!isNew && user">
      <h3>API Keys</h3>
      <user-api-key-table :user="user.id"/>
    </div>
  </b-container>
</template>

<script>
  import uuidv4 from 'uuid/v4';
  import {debounce} from 'lodash';
  import io from '@/socket-instance';

  import LoadingSpinner from '@/components/LoadingSpinner';
  import UuidDisplay from '@/components/UuidDisplay';
  import UserRoleTable from '@/components/UserRoleTable';
  import UserApiKeyTable from '@/components/UserApiKeyTable';

  export default {
    name: 'EditUser',

    components: {
      LoadingSpinner,
      UuidDisplay,
      UserRoleTable,
      UserApiKeyTable,
    },

    mounted() {
      this.routeChanged();
    },

    computed: {
      isNew() {
        return this.$route.params.slug == "create";
      },
      title() {
        return this.isNew? "New User" : "Modify User";
      },
      passwordLabel() {
        return this.isNew? 'Password' : 'Change Password';
      },
      editable() {
        return (this.isNew && this.canCreate) || (!this.isNew && this.canManage);
      },
      canCreate() {
        return this.$store.getters['auth/hasGlobalPermission']('createUsers');
      },
      canManage() {
        return this.$store.getters['auth/hasGlobalPermission']('modifyUsers');
      },
      canDelete() {
        return this.$store.getters['auth/hasGlobalPermission']('deleteUsers');
      },
      isSuper() {
        return this.$store.state.auth.user.is_super;
      },
    },

    methods: {
      routeChanged() {
        if(this.isNew) {
          this.newUser();
        } else {
          this.loadUser(this.$route.params.slug);
        }
        if(this.$route.query.msg) {
          this.alert = this.$route.query.msg;
        }
      },
      touch() {
        this.dirty = true;
        this.alert = null;
      },
      newUser() {
        this.user = {
          id: uuidv4(),
          first_name: '',
          last_name: '',
          slug: '',
          email: '',
          phone: '',
          password: '',
          is_super: false,
        };

        this.roles = [];
        this.slugValid = null;
        this.busy = false;
        this.dirty = false;
      },
      loadUser(slug) {
        this.user = null;
        this.roles = null;
        io.emit('users/loadUser', slug, (result) => {
          if(result.error) {
            this.error = result.error;
          } else {
            this.user = result.user;
            this.roles = result.roles;
            this.user.password = '';
          }
          this.busy = false;
          this.dirty = false;
        });
      },
      createUser() {
        this.busy = true;
        io.emit('users/createUser', this.user, (result) => {
          if(result.error) {
            this.error = result.error;
          } else {
            this.$router.push({
              name: 'edit-user',
              params: {slug: this.user.slug},
              query: {msg: "User created."}
            });
          }
          this.busy = false;
        });
      },
      saveUser() {
        this.busy = true;
        io.emit('users/saveUser', this.user, (result) => {
          if(result.error) {
            this.error = result.error;
          } else {
            this.dirty = false; 
            this.alert = "User saved.";
          }
          this.busy = false;
        });
      },
      deleteUser() {
        io.emit('users/deleteUser', this.user, (result) => {
          if(result.error) {
            this.error = result.error;
          } else {
            this.$router.push({name: 'users'});
          }
          this.busy = false;
        });
      },
      async confirmDeleteUser() {
        let confirmation = await this.$bvModal.msgBoxConfirm(
          `Delete user "${this.user.slug}"?`,
          { okVariant: 'danger', okTitle: 'Delete', 
            title: 'Confirm Deletion'});
        if(confirmation) {
          this.deleteUser();
        }
      },
      checkSlug: debounce(function() {
        const args = {
          slug: this.user.slug,
          id: this.user.id
        };
        io.emit('users/checkSlug', args, (result) => {
          this.slugValid = result.ok;
          this.slugFeedback = result.feedback;
        });
      }, 300),
    },

    data() {
      return {
        user: null,
        roles: null,
        error: null,
        slugValid: null,
        slugFeedback: '',
        busy: false,
        dirty: false,
        alert: null,
      }
    },

    watch: {
      '$route'() {
        this.routeChanged();
      },
      'user.slug'(slug) {
        this.slugValid = null;
        if(slug !== undefined && slug.length > 0) {
          this.checkSlug();
        }
      },
    },
  }
</script>

<style scoped>
  .roles {
    margin-top: 32px;
  }
</style>
