import {library} from '@fortawesome/fontawesome-svg-core';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import Vue from 'vue';

import {
  faUser,
  faPlus,
  faPencilAlt,
  faTrashAlt,
  faSave,
  faSearch,
  faTimes,
  faStar,
  faRedo,
  faRunning,
  faHandPaper,
  faPlay,
  faStop,
  faEye,
  faEyeSlash,
  faCopy,
  faTicketAlt,
  faClipboard,
  faLink,
  faClipboardCheck,
  faClipboardList
} from '@fortawesome/free-solid-svg-icons';

library.add(
  faUser,
  faPlus,
  faPencilAlt,
  faTrashAlt,
  faSave,
  faSearch,
  faTimes,
  faStar,
  faRedo,
  faRunning,
  faHandPaper,
  faPlay,
  faStop,
  faEye,
  faEyeSlash,
  faCopy,
  faTicketAlt,
  faClipboard,
  faLink,
  faClipboardCheck,
  faClipboardList
);

Vue.component('fa-icon', FontAwesomeIcon); 
