<template>
  <b-container>
    <h1>{{title}}</h1>
    <b-alert v-if="error && !brand" variant="danger" show>{{error}}</b-alert>

    <loading-spinner v-if="!brand && !error"/>
    <div v-else-if="brand">
      <b-form @input="touch" @submit.prevent="">
        <b-form-group label-for="input-displayname" label-cols-md="3"
          label="Brand Title">
          <b-input id="input-displayname" v-model="form.display_name"
            :disabled="!editable"/>
        </b-form-group>
        <b-form-group label-for="input-slug" label-cols-md="3" label="Slug" 
          :valid-feedback="slugFeedback" :invalid-feedback="slugFeedback">
          <b-input id="input-slug" v-model="form.slug"
            :disabled="!editable" :state="slugValid"/>
        </b-form-group>
        <b-form-group label-cols-md="3"
          label="UUID">
          <uuid-display :value="form.id"/>
        </b-form-group>
        <b-form-group label-for="input-bio" label-cols-md="3"
          label="Bio">
          <b-form-textarea id="input-bio" v-model="form.bio"
            :disabled="!editable"/>
        </b-form-group>
        <b-form-group label-for="input-ticketurl" label-cols-md="3"
          label="Default Ticket URL"
          description="#TICKET# will be replaced with the ticket token">
          <b-input id="input-ticketurl" v-model="form.default_ticket_url"
            :disabled="!editable"/>
        </b-form-group>

        <!--
        <h3 class="mt-3">Brand Links</h3>
        <b-form-group label-for="input-image" label-cols-md="3"
          label="Image">
          <b-input id="input-image" v-model="form.image"
            :disabled="!editable"/>
        </b-form-group>
        <b-form-group label-for="input-merch" label-cols-md="3"
          label="Merch">
          <b-input id="input-merch" v-model="form.merch"
            :disabled="!editable"/>
        </b-form-group>
        <b-form-group label-for="input-spotify" label-cols-md="3"
          label="Spotify">
          <b-input id="input-spotify" v-model="form.spotify"
            :disabled="!editable"/>
        </b-form-group>
        <b-form-group label-for="input-applemusic" label-cols-md="3"
          label="Apple Music">
          <b-input id="input-applemusic" v-model="form.applemusic"
            :disabled="!editable"/>
        </b-form-group>
        <b-form-group label-for="input-venmo" label-cols-md="3"
          label="Venmo">
          <b-input id="input-venmo" v-model="form.venmo"
            :disabled="!editable"/>
        </b-form-group>
        <b-form-group label-for="input-cashapp" label-cols-md="3"
          label="Cash App">
          <b-input id="input-cashapp" v-model="form.cash_app"
            :disabled="!editable"/>
        </b-form-group>
        -->

        <b-alert show v-if="error" variant="danger">{{error}}</b-alert>
        <b-alert show v-else-if="alert">{{alert}}</b-alert>

        <div class="button-box mb-3">
          <b-button v-if="isNew" :disabled="!canCreate || !isValid || busy"
            variant="primary" @click="createBrand(form)">
            <fa-icon icon="plus"/> Create Brand
          </b-button>
          <b-button v-else :disabled="!dirty || busy || !canModify"
            variant="primary" @click="saveBrand(form)">
            <fa-icon icon="save"/> Save Brand
          </b-button>
          <b-button v-if="canDelete && !isNew" variant="danger"
            @click="confirmDelete">
            <fa-icon icon="trash-alt"/> Delete Brand
          </b-button>
          <b-spinner v-if="busy"/>
        </div>
      </b-form>

      <template v-if="events">
        <h3 class="mt-3">Brand Events</h3>
        <b-table :items="events" :fields="eventFields" v-if="events.length > 0">
          <template v-slot:cell(title)="data">
            <trunc>
              <router-link :to="`/admin/events/${data.item.slug}`">
                {{data.value}}
              </router-link>
            </trunc>
          </template>
        </b-table>
        <div v-else>
          <em>This Brand has no events yet.</em>
        </div>
        <div class="mt-3">
          <b-button variant="primary" v-if="canCreateEvents" @click="createEvent">
            <fa-icon icon="plus"/> Create Event
          </b-button>
        </div>
      </template>
      
    </div>

  </b-container>
</template>

<script>
  import {mapState, mapActions} from 'vuex';
  import {debounce} from 'lodash';
  import io from '@/socket-instance';
  import {formatDateTime, formatMinutes} from '@/common/times';

  import LoadingSpinner from '@/components/LoadingSpinner';
  import UuidDisplay from '@/components/UuidDisplay';

  export default {
    name: 'EditBrand',

    components: {
      LoadingSpinner,
      UuidDisplay,
    },

    mounted() {
      this.routeChanged();
    },

    computed: {
      ...mapState('brand', [
        'brand',
        'events',
        'permissions',
        'error',
        'busy',
        'isNew',
      ]),
      title() {
        return this.isNew? 'New Brand' : 'Edit Brand';
      },
      editable() {
        return (this.isNew && this.canCreate) || this.canModify;
      },
      canModify() {
        return this.hasPermission('modifyArtistBrands');
      },
      canDelete() {
        return this.hasPermission('deleteArtistBrands');
      },
      canCreateEvents() {
        return this.hasPermission('createEvents');
      },
      canCreate() {
        return this.$store.getters['auth/hasGlobalPermission']('createArtistBrands');
      },
      isValid() {
        return this.slugValid && this.form.display_name;
      }
    },

    methods: {
      ...mapActions('brand', ['loadBrand', 'newBrand', 'createBrand',
        'saveBrand', 'deleteBrand']),
      routeChanged() {
        if(this.$route.params.slug == 'create') {
          this.newBrand();
        } else {
          this.loadBrand(this.$route.params.slug);
        }
        this.alert = this.$route.params.msg;
        this.slugValid = null;
        this.slugFeedback = null;
      },
      touch() {
        this.dirty = true;
        this.alert = null;
      },
      hasPermission(permission) {
        return this.permissions.includes(permission);
      },
      checkSlug: debounce(function() {
        const args = {
          slug: this.form.slug,
          id: this.brand.id,
        };
        io.emit('brands/checkSlug', args, (result) => {
          this.slugValid = result.ok;
          this.slugFeedback = result.feedback;
        });
      }, 300),
      async confirmDelete() {
        let confirmation = await this.$bvModal.msgBoxConfirm(
          `Delete brand "${this.brand.title}"?`,
          { okVariant: 'danger', okTitle: 'Delete', 
            title: 'Confirm Deletion'});
        if(confirmation) {
          this.deleteBrand(this.form.id);
        }
      },
      createEvent() {
        this.$router.push({
          name: 'edit-event',
          params: {slug: 'create', brandId: this.brand.id, 
            ticketUrl: this.brand.default_ticket_url},
        });
      },
    },

    watch: {
      '$route'() {
        this.routeChanged();
      },
      brand(b) {
        this.form = Object.assign({}, b);
      },
      'form.slug'(slug) {
        this.slugValid = null;
        if(slug !== undefined && slug.length > 0) {
          this.checkSlug();
        }
      },
    },

    data() { 
      return {
        form: {},
        dirty: false,
        alert: null,
        slugValid: null,
        slugFeedback: null,
        eventFields: [
          { key: 'title', label: 'Event Title' },
          { key: 'datetime', label: 'Start Time', formatter: formatDateTime},
          { key: 'duration', label: 'Duration', formatter: formatMinutes },
        ],
      };
    },
  }
</script>

<style scoped>

</style>
