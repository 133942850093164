<template>
  <paginated-list title="Streams" :error="error" :items="items" :count="count"
    :page-busy="pageBusy" :pagesize="pagesize" :page="page"
    empty-placeholder="No streams found."
    @invalidate="INVALIDATE_RESULTS" @search="listStreams" @loadPage="loadPage">

    <template v-slot:table>
      <b-table :items="items" :fields="fields">
        <template v-slot:cell(slug)="data">
          <router-link :to="`/admin/streams/${data.item.slug}`">
            <strong>{{data.value}}</strong>
          </router-link>
          <b-badge class="ml-1" v-if="data.item.active" variant="primary">Active</b-badge>
        </template>
        <template v-slot:cell(state)="data">
          <b-badge :variant="streamStateBadgeVariant(data.value)">
            {{formatStreamStateBadge(data.value)}}
          </b-badge>
        </template>
        <template v-slot:cell(event_title)="data">
          <trunc>
            <router-link :to="`/admin/events/${data.item.event_slug}`">
              {{data.value}}
            </router-link>
          </trunc>
        </template>
        <template v-slot:cell(artist_brand_title)="data">
          <trunc>
            <router-link :to="`/admin/brands/${data.item.artist_brand_slug}`">
              {{data.value}}
            </router-link>
          </trunc>
        </template>
      </b-table>
    </template>

  </paginated-list>
</template>

<script>
  import {mapState, mapActions, mapMutations} from 'vuex';
  import {streamStateBadgeVariant, formatStreamStateBadge,
    formatProviderName}
    from '@/common/streams';

  import PaginatedList from '@/components/PaginatedList';

  export default {
    name: 'Streams',
    components: {
      PaginatedList
    },

    mounted() {
      this.listStreams();
    },

    computed: {
      ...mapState('streams', [
        'items',
        'error',
        'pageBusy',
        'count',
        'pagesize',
        'page',
      ]),
      canCreate() {
        return this.$store.getters['auth/hasGlobalPermission']('createStreams');
      },
    },

    methods: {
      ...mapActions('streams', [
        'listStreams',
        'loadPage',
      ]),
      ...mapMutations('streams', [
        'INVALIDATE_RESULTS',
      ]),
      streamStateBadgeVariant,
      formatStreamStateBadge,
      formatProviderName,
      doLoad(foo) {
        console.log('doLoad', foo);
      }
    },

    data() {
      return {
        fields: [
          { key: 'slug', label: 'Stream' },
          { key: 'event_title', label: 'Event' },
          { key: 'artist_brand_title', label: 'Artist Brand' },
          { key: 'provider', label: 'Video Provider', formatter: formatProviderName },
          { key: 'state', label: 'Status' },
        ]
      };
    },
  }
</script>

<style scoped>

</style>
