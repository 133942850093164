import io from '@/socket-instance';
import uuidv4 from 'uuid/v4';
import router from '@/router';

export default {
  namespaced: true,

  state: {
    event: null,
    streams: null,
    permissions: [],
    busy: false,
    error: null,
    subscription: null,
    isNew: false,
    activityLog: null,
    eventData: null,
    interactions: {},
  },

  mutations: {
    START_LOADING(state) {
      state.event = null;
      state.streams = null;
      state.busy = true;
      state.error = null;
      state.isNew = false;
      state.permissions = [];
      state.activityLog = null;
      state.eventData = null;
      state.interactions = {};
    },

    START_SAVING(state) {
      state.busy = true;
      state.error = null;
    },

    FINISH_SAVING(state) {
      state.busy = false;
    },

    SET_SUBSCRIPTION(state, subscription) {
      state.subscription = subscription;
    },

    EVENT_LOADED(state, {event, streams, permissions}) {
      state.event = event;
      state.streams = streams;
      state.permissions = permissions;
      state.busy = false;
      state.error = false;
      state.isNew = false;
    },

    SET_BUSY(state, busy) {
      state.busy = busy;
    },

    SET_ERROR(state, error) {
      state.error = error;
      state.busy = false;
    },

    SET_EVENT_STATE(state, newState) {
      if(!state.event) return;
      state.event.state = newState;
    },

    SET_ACTIVITY_LOG(state, log) {
      state.activityLog = log;
    },

    SET_EVENT_DATA(state, data) {
      state.eventData = data;
    },

    NEW_EVENT(state, options) {
      if(!options) options = {};
      state.event = {
        id: uuidv4(),
        display_name: '',
        slug: '',
        artist_brand_id: options.brandId || null,
        ticket_url: options.ticketUrl || 'https://streamiq.net/venue/?t=#TICKET#',
      };
      state.streams = null;
      state.busy = false;
      state.error = null;
      state.isNew = true;
      state.permissions = [];
    },

    ADD_STREAM(state, stream) {
      if(state.streams) {
        state.streams.push(stream);
      }
    },

    SOCKET_ACTIVESTREAMCHANGED(state, {event, stream}) {
      if(!state.event || state.event.id !== event) return;
      state.event.active_stream_id = stream;
      if(state.streams) {
        for(let s of state.streams) {
          s.active = (stream === s.id);
        }
      }
    },

    SOCKET_STREAMSTATECHANGED(state, payload) {
      if(!state.event || state.event.id !== payload.event) return;
      if(state.streams) {
        for(let s of state.streams) {
          if(s.id === payload.stream) {
            s.state = payload.state;
          }
        }
      }
    },

    SOCKET_STREAMDELETED(state, payload) {
      if(!state.event || state.event.id !== payload.event) return;
      if(state.streams) {
        for(let i=0; i<state.streams.length; i++) {
          if(state.streams[i].id == payload.stream) {
            state.streams.splice(i, 1);
            return;
          }
        }
      }
    },

    SOCKET_EVENTTICKETSTATS(state, payload) {
      if(!state.event) return;
      state.event.tickets_created = payload.tickets_created;
      state.event.tickets_revoked = payload.tickets_revoked;
    },

    SOCKET_INTERACTIONS(state, payload) {
      console.log('interactions', payload);
      const newInteractions = {
        ...state.interactions
      }
      for(const key of Object.keys(payload)) {
        newInteractions[key] = newInteractions[key] === undefined ?
          payload[key] :
          payload[key] + newInteractions[key];
      }
      state.interactions = newInteractions;
    },

    RESET_INTERACTIONS(state) {
      state.interactions = {};
    }

  },

  actions: {
    async loadEvent({commit, dispatch}, id) {
      commit('START_LOADING');
      await dispatch('unsubscribe');
      io.emit('events/loadEvent', id, (result) => {
        if(!result.error) {
          commit('EVENT_LOADED', result);
          dispatch('subscribe', result.event.id);
          dispatch('loadActivityLog', result.event.id);
          dispatch('loadEventData', result.event.id);
        } else {
          commit('SET_ERROR', result.error);
        }
      });
    },

    newEvent({commit, dispatch}, options) {
      dispatch('unsubscribe');
      commit('NEW_EVENT', options);
    },

    createEvent({commit}, event) {
      commit('START_SAVING');
      io.emit('events/createEvent', event, (result) => {
        if(result.error) {
          commit('SET_ERROR', result.error);
        } else {
          router.push({
            name: 'edit-event',
            params: {slug: event.slug},
            query: {msg: "Event created."}
          });
        }
      });
    },

    saveEvent({commit}, event) {
      commit('START_SAVING');
      io.emit('events/saveEvent', event, (result) => {
        if(result.error) {
          commit('SET_ERROR', result.error);
        } else {
          router.push({
            name: 'events',
          });
        }
      });
    },

    deleteEvent({commit, dispatch}, eventId) {
      commit('START_SAVING');
      io.emit('events/deleteEvent', eventId, (result) => {
        if(result.error) {
          commit('SET_ERROR', result.error);
        } else {
          router.push({
            name: 'events',
          });
        }
      });
    },

    loadActivityLog({commit}, eventId) {
      io.emit('events/loadEventLog',  eventId, (result) => {
        if(result.error) {
          commit('SET_ERROR', result.error);
        } else {
          commit('SET_ACTIVITY_LOG', result.log);
        }
      });
    },

    loadEventData({commit}, eventId) {
      io.emit('events/loadEventData',  eventId, (result) => {
        if(result.error) {
          commit('SET_ERROR', result.error);
        } else {
          commit('SET_EVENT_DATA', result.data);
        }
      });
    },

    saveEventData({commit}, {eventId, data}) {
      return new Promise((resolve, reject) => {
        const args = {
          id: eventId,
          data
        };
        io.emit('events/saveEventData', args, (result) => {
          if(result.error) {
            reject(result.error);
          } else {
            commit('SET_EVENT_DATA', data);
            resolve(result);
          }
        });
      });
    },

    setEventState({commit}, {eventId, state}) {
      return new Promise((resolve, reject) => {
        const args = {
          id: eventId,
          state
        };
        io.emit('events/setEventState', args, (result) => {
          if(result.error) {
            reject(result.error);
          } else {
            resolve(result);
          }
        });
      });
    },

    createTicket({state}, {eventId, options}) {
      return new Promise((resolve, reject) => {
        io.emit('events/createTicket', eventId, options, (result) => {
          if(result.error) {
            reject(result.error);
          } else {
            resolve(result.ticket);
          }
        });
      });
    },

    async subscribe({commit, state, dispatch}, id) {
      await dispatch('unsubscribe');
      return new Promise((resolve, reject) => {
        io.emit('events/subscribeToEvent', id, (result) => {
          if(!result.error) {
            commit('SET_SUBSCRIPTION', id);
          } else {
            console.log('event subscription error: ', result.error);
          }
          resolve();
        });
      });
    },

    unsubscribe({commit, state}) {
      if(!state.subscription) return Promise.resolve();
      return new Promise((resolve, reject) => {
        io.emit('events/unsubscribeFromEvent', state.subscription, (result) => {
          if(!result.error) {
            commit('SET_SUBSCRIPTION', null);
          } else {
            console.log('event unsubscription error: ', result.error);
          }
          resolve();
        });
      });
    },

    socket_streamCreated({commit, state}, {event, stream}) {
      if(!state.event || state.event.id !== event) return;
      io.emit('streams/loadStreamSummary', stream, (result) => {
        if(result.stream) {
          commit('ADD_STREAM', result.stream);
        } else if(result.error) {
          console.log('load stream error: ', result.error);
        }
      });
    },

    socket_eventStateChanged({commit, state, dispatch}, args) {
      if(!state.event || state.event.id !== args.event) return;
      commit('SET_EVENT_STATE', args.state);
      dispatch('loadActivityLog', state.event.id);
    }

  },
}
