import io from '@/socket-instance';

export default {
  namespaced: true,

  state: {
    stream: null,
    permissions: [],
    error: null,
    busy: false,
  },

  mutations: {
    START_LOADING(state) {
      state.stream = null;
      state.permissions = [];
      state.error = null;
      state.busy = false;
    },
    STREAM_LOADED(state, {stream, permissions}) {
      state.stream = stream;
      state.permissions = permissions;
      state.error = null;
      state.busy = false;
    },
    SET_ERROR(state, error) {
      state.busy = false;
      state.error = error;
    },
    SOCKET_ACTIVESTREAMCHANGED(state, {event, stream}) {
      if(!state.stream) return;
      state.stream.active = (stream === state.stream.id);
    },
    SOCKET_STREAMSTATECHANGED(state, payload) {
      if(!state.stream) return;
      if(payload.stream === state.stream.id) {
        state.stream.state = payload.state;
      }
    },
    SOCKET_STREAMDELETED(state, payload) {
      if(!state.stream) return;
      if(payload.stream === state.stream.id) {
        state.stream = null;
        state.permissions = [];
        state.error = "This stream has been deleted.";
      }
    },
  },

  actions: {
    loadStream({commit, dispatch}, id) {
      io.emit('streams/loadStream', id, (result) => {
        if(result.error) {
          commit('SET_ERROR', result.error);
        } else {
          commit('STREAM_LOADED', result);
          dispatch('event/subscribe', result.stream.event_id, {root: true});
        }
      });
    },
  },
};
