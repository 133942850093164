<template>
  <b-container>
    <b-jumbotron header="Not Found"
      lead="This route is invalid or does not yet exist.">
      <p>Maybe Brian is still building it?</p>
      <b-button variant="secondary" to="/admin" class="mr-3">Backstage Home</b-button>
      <b-button variant="primary" @click="$router.go(-1)">Go Back</b-button>
    </b-jumbotron>
  </b-container>
</template>

<script>
  export default {
    name: 'InvalidRoute'
  }
</script>

<style scoped>

</style>
