<template>
  <div class="form-static">
    <slot></slot>
  </div>
</template>

<script>
  export default {
    name: 'FormStatic',
  }
</script>

<style scoped>
  .form-static {
    color: gray;
    padding: 5px;
    border: 1px solid rgba(0,0,0,0.125);
    border-radius: 6px;
  }
</style>
