<template>
  <b-container>

  </b-container>
</template>

<script>

export default {
  name: 'Home',
  components: {
  }
}
</script>
