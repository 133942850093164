import io from '@/socket-instance';

export default { 
  namespaced: true,

  state: {
    users: null,
    error: null,
    query: null,
    pageBusy: false,
    count: 0,
    pagesize: 25,
    page: 1
  },

  getters: {
    pages(state) {
      if(state.count == 0) return 1;
      return Math.ceil(state.count / state.pagesize);
    }
  },

  mutations: {
    SET_ERROR(state, error) {
      state.users = null;
      state.error = error;
    },
    START_QUERY(state, query) {
      state.users = null;
      state.error = null;
      state.query = query;
      state.count = 0;
      state.page = 1;
    },
    QUERY_RESULT(state, {users, count}) {
      state.users = users;
      state.error = null;
      state.count = count;
    },
    START_LOAD_PAGE(state, page) {
      state.page = page;
      state.pageBusy = true;
      state.error = null;
    },
    LOAD_PAGE_RESULT(state, {users}) {
      state.users = users;
      state.pageBusy = false;
      state.error = null;
    },
    INVALIDATE_RESULTS(state) {
      state.users = null;
    }
  },

  actions: {
    listUsers({commit, state}, query) {
      commit('START_QUERY', query);

      const args = {
        query: state.query,
        limit: state.pagesize,
        offset: (state.page-1) * state.pagesize,
      };

      io.emit('users/listUsers', args, (result) => {
        if(result.error) {
          commit('SET_ERROR', result.error);
        } else {
          commit('QUERY_RESULT', result);
        }
      });
    },

    loadPage({commit, state, getters}, page) {
      if(page > getters.pages) {
        page = getters.pages;
      }

      commit('START_LOAD_PAGE', page);

      const args = {
        query: state.query,
        limit: state.pagesize,
        offset: (state.page-1) * state.pagesize,
      };

      io.emit('users/listUsers', args, (result) => {
        if(result.error) {
          commit('SET_ERROR', result.error);
        } else {
          commit('LOAD_PAGE_RESULT', result);
        }
      });
    },
  },
};
