<template>
  <b-navbar toggleable="md" type="dark" variant="dark">
    <b-navbar-brand>StreamIQ Dashboard</b-navbar-brand>
    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav>
        <template v-if="user">
          <b-nav-item to="/admin/brands">Brands</b-nav-item>
          <b-nav-item to="/admin/events">Events</b-nav-item>
          <b-nav-item to="/admin/streams">Streams</b-nav-item>
          <b-nav-item v-if="hasGlobalPermission('viewUsers')"
            to="/admin/users">Users</b-nav-item>
          <b-nav-item v-if="hasGlobalPermission('viewRoles')" 
            to="/admin/roles">Roles</b-nav-item>
          <b-nav-item-dropdown v-if="isSuper" text="Tools">
            <b-dropdown-item to="/admin/what-can">What can...</b-dropdown-item>
          </b-nav-item-dropdown>
        </template>
      </b-navbar-nav>

      <b-navbar-nav class="ml-auto">
        <user-dropdown v-if="user"/>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
  import {mapState, mapGetters} from 'vuex';

  import UserDropdown from '@/components/UserDropdown';

  export default {
    name: 'MainNav',

    components: {
      UserDropdown,
    },

    computed: {
      ...mapGetters('auth', ['hasGlobalPermission', 'isSuper']),
      ...mapState('auth', ['user']),
    }
  }
</script>

<style scoped>

</style>
