<template>
  <div>
    <b-spinner v-if="data === null || data === undefined"/>
    <template v-else>
      <h3>Event Data</h3>
      <p>Top level properties in this object is shared to the client and only made available when the stream is live.</p>
      <p>Properties under the "public" object are available on page load via CDN. The public data is auto-populated on event creation, 
      but it otherwise is not updated so changing the title of the event must be done here if you wish to see it in the stream GUI.</p>
      <p>Some of the public properties are required for the streaming GUI to function, so please be careful about removing them.</p>
      <json-editor :options="options" @input="dirty = true" v-model="data"/>
      <div class="mt-3">
        <b-button v-if="!readOnly" variant="primary" :disabled="!dirty || saving" 
          class="mr-3" @click="save">
          Save Event Data
        </b-button>
        <b-spinner v-if="saving"/>
      </div>
    </template>
  </div>
</template>

<script>
  import JsonEditor from 'v-jsoneditor';
  import {mapState, mapActions} from 'vuex';

  export default {
    name: 'EventConfigEditor',
    components: {
      JsonEditor,
    },

    props: {
      readOnly: { type: Boolean, default: false },
    },

    mounted() {
      this.dirty = false;
    },

    watch: {
      $route() {
        this.dirty = false;
      },

      eventData() {
        if(!this.dirty) this.data = this.eventData;
      },
    },

    computed: {
      ...mapState('event', ['eventData', 'event']),

      options() {
        return {
          mode: this.readOnly? 'view' : 'tree',
        };
      },
    },

    methods: {
      ...mapActions('event', ['saveEventData']),
      async save() {
        this.saving = true;
        await this.saveEventData({
          eventId: this.event.id,
          data: this.data
        });
        this.saving = false;
        this.dirty = false;
      }
    },


    data() {
      return {
        data: this.$store.state.event.eventData,
        dirty: false,
        saving: false,
      }
    },
  }
</script>

<style scoped>

</style>
