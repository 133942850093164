<template>
  <div class="interaction-chart">
    <b-table :items="items" thead-class="d-none" :fields="fields" :fixed="true"
      v-if="Object.keys(interactions).length > 0">
      <template #table-colgroup="scope">
        <col style=""></col>
        <col></col>
        <col style="width: 70%"></col>
      </template>

      <template #cell(bar)="data">
        <div class="chart-bar" :style="`width: ${data.value}%`"></div>
      </template>
    </b-table>
    <div v-else><em>No interactions yet.</em></div>

    <b-button class="mt-3" @click="RESET_INTERACTIONS">Reset</b-button>
  </div>
</template>

<script>
  import {mapState, mapMutations} from 'vuex';

  export default {
    name: 'InteractionViewer',
    components: {
    },

    computed: {
      ...mapState('event', ['interactions']),
      items() {
        const items = [];
        const maxval = Math.max(...Object.values(this.interactions));
        for(const key of Object.keys(this.interactions)) {
          items.push({
            name: key,
            count: this.interactions[key],
            bar: Math.round(this.interactions[key] / maxval * 100),
          });
        }
        return items;
      },
    },

    methods: {
      ...mapMutations('event', ['RESET_INTERACTIONS']),
    },

    data() {
      return {
        fields: [
          { key: 'name', thStyle: 'width: 100px !important' },
          { key: 'count',  thStyle: 'width: 100px !important' },
          { key: 'bar', thStyle: 'width: 400px !important'  },
        ]
      };
    }

  }
</script>

<style scoped>
  .chart-bar {
    background: #007bff;
    height: 1.5em; 
    transition: width 0.5s ease-in;
  }

  .freddie {
    width: 100px !important;
    background: red;
    font-weight: bold;
  }
</style>
