<template>
  <b-container>
    <b-alert v-if="error" variant="danger" show>{{error}}</b-alert>
    <loading-spinner v-if="!error && !stream"/>
    <template v-if="stream">
      <h1 class="mb-3">{{stream.slug}}
        <b-badge v-if="stream.active" class="mr-3" variant="light">
          <fa-icon icon="star"/> Active
        </b-badge>
        <b-badge class="mr-3" :variant="streamStateBadgeVariant(stream.state)">
          {{formatStreamStateBadge(stream.state)}}
        </b-badge>

      </h1>
      <b-form-group label-cols-md="3" label="Artist Brand:">
        <form-static>
          <router-link :to="`/admin/brands/${stream.artist_brand_slug}`">
            {{stream.artist_brand_title}}
          </router-link>
        </form-static>
      </b-form-group>
      <b-form-group label-cols-md="3" label="Event:">
        <form-static>
          <router-link :to="`/admin/events/${stream.event_slug}`">
            {{stream.event_title}}
          </router-link>
        </form-static>
      </b-form-group>
      <b-form-group label-cols-md="3" label="Provider:">
        <form-static>
          {{formatProviderName(stream.provider)}}
        </form-static>
      </b-form-group>
      <b-form-group label-cols-md="3" label="UUID:">
        <uuid-display :value="stream.id"/>
      </b-form-group>

      <b-alert v-model="streamErrorVisible" variant="danger" dismissible>
        {{streamError}}
      </b-alert>

      <div class="button-box mb-3">
        <b-button v-if="canModifyEvents && !stream.active"
          variant="primary" @click="streamAction('activate')">
          <fa-icon icon="star"/> Activate Stream
        </b-button>
        <b-button v-if="canStartStopStreams && stream.state=='READY'"
          variant="success" @click="streamAction('start')">
          <fa-icon icon="running"/> Start Stream
        </b-button>
        <b-button v-if="canStartStopStreams && stream.state=='RUNNING'"
          variant="danger" @click="streamAction('stop')">
          <fa-icon icon="hand-paper"/> Stop Stream
        </b-button>
        <b-button v-if="canStartStopStreams && stream.state=='FINISHED'"
          variant="secondary" @click="streamAction('reset')">
          <fa-icon icon="redo"/> Reset Stream
        </b-button>
        <b-button v-if="canDeleteStreams && (stream.state=='READY' 
          || stream.state=='FINISHED' || stream.state=='FAILED')"
          variant="danger" @click="confirmDelete">
          <fa-icon icon="trash-alt"/> Delete Stream
        </b-button>

        <b-spinner v-if="stream.state=='STARTING' || stream.state=='STOPPING'
          || stream.state=='PENDING'"/>
      </div>

      <template v-if="stream.inputs">
        <h3 class="mt-3">Stream Inputs</h3>
        <b-alert v-if="stream.inputs.error" variant="danger" show>
          {{stream.inputs.error}}
        </b-alert>
        <b-list-group v-else class="mb-2">
          <b-list-group-item v-for="(url, key) of sortUnordered(stream.inputs)" :key="key">
            <b-row>
              <b-col >
                <b-input-group size="sm">
                  <b-input-group-prepend>
                    <b-button size="sm" :variant="key=='primary'? 'primary' : 'secondary'" :disabled="true">{{key}} server</b-button>
                  </b-input-group-prepend>
                  <!-- <b-input-group-prepend is-text><b>server</b></b-input-group-prepend> -->
                  <b-form-input :ref="`${key}-server-input`"  :value="url.substring(0, url.lastIndexOf('/')+1)" :readonly="true"></b-form-input>      
                  <b-input-group-append>
                    <b-button class="copy-btn" v-on:click="copyUrl(`${key}-server-input`)"><fa-icon icon="copy"></fa-icon></b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-col>
              <b-col cols="5">
                <b-input-group size="sm">
                  <b-input-group-prepend>
                    <b-button size="sm" :variant="key=='primary'? 'primary' : 'secondary'" :disabled="true">key</b-button>
                  </b-input-group-prepend>
                  <b-form-input :ref="`${key}-key-input`" :value="url.substring(url.lastIndexOf('/')+1, url.length)"  :readonly="true"></b-form-input>
                  <b-input-group-append>
                    <b-button class="copy-btn" v-on:click="copyUrl(`${key}-key-input`)"><fa-icon icon="copy"></fa-icon></b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-col>
            </b-row>
          </b-list-group-item>
        </b-list-group>
      </template>

      <template v-if="stream.outputs">
        <h3 class="mt-3">Stream Outputs</h3>
        <b-alert v-if="stream.outputs.error" variant="danger" show>
          {{stream.outputs.error}}
        </b-alert>
        <b-list-group v-else>
          <b-card v-for="(outputs, group) of stream.outputs" :key="group"
            :header="outputGroupTitle(group)" class="mb-2" no-body>
            <b-list-group flush>
              <b-list-group-item v-for="(url, key) of outputs" :key="key">
                <b-input-group size="sm">
                  <b-input-group-prepend>
                    <b-button size="sm" :variant="key=='hls'? 'primary' : 'secondary'" :disabled="true">{{key.toUpperCase()}}</b-button>
                  </b-input-group-prepend>
                  <b-form-input :ref="`${key}-output`":value="url" :readonly="true"></b-form-input>
                  <b-input-group-append>
                    <b-button class="copy-btn" v-on:click="copyUrl(`${key}-output`)"><fa-icon icon="copy"></fa-icon></b-button>
                  </b-input-group-append>      
                </b-input-group>
                <b-badge>
                  
                </b-badge>
              </b-list-group-item>
            </b-list-group>
          </b-card>
        </b-list-group>
      </template>

    </template>

  </b-container>
</template>

<script>
  import io from '@/socket-instance';
  import {mapState, mapActions} from 'vuex';
  import {streamStateBadgeVariant, formatStreamStateBadge, formatProviderName}
    from '@/common/streams';  
  
  import LoadingSpinner from '@/components/LoadingSpinner';
  import FormStatic from '@/components/FormStatic';
  import UuidDisplay from '@/components/UuidDisplay';
  import InvalidRoute from './InvalidRoute.vue';

  export default {
    name: 'StreamDetails',

    components: {
      LoadingSpinner,
      FormStatic,
      UuidDisplay,
      InvalidRoute,
    },

    mounted() {
      this.routeChanged();
    },

    computed: {
      ...mapState('stream', [
        'stream',
        'permissions',
        'error',
        'busy',
      ]),
      canStartStopStreams() {
        return this.hasPermission('startStopStreams');
      },
      canDeleteStreams() {
        return this.hasPermission('deleteStreams');
      },
      canModifyEvents() {
        return this.hasPermission('modifyEvents');
      },
    },

    methods: {
      ...mapActions('stream', ['loadStream']),
      streamStateBadgeVariant,
      formatStreamStateBadge,
      formatProviderName,
      routeChanged() {
        this.loadStream(this.$route.params.slug);
        this.streamError = null;
        this.streamErrorVisible = false;
      },
      copyUrl(key){
        this.$refs[key][0].$el.select();
        document.execCommand("copy")
      },
      sortUnordered(unordered) {
        const ordered = Object.keys(unordered).sort().reverse().reduce(
          (obj, key) => { 
            obj[key] = unordered[key]; 
            return obj;
          }, 
          {}
        );
        return ordered;
      },
      showStreamError(text) {
        this.streamError = text;
        this.streamErrorVisible = true;
      },
      streamAction(action) {
        io.emit(`streams/${action}Stream`, this.stream.id, (result) => {
          if(result.error) {
            this.showStreamError(result.error);
          }
        });
      },
      hasPermission(permission) {
        return this.permissions.includes(permission);
      },      
      async confirmDelete() {
        let confirmation = await this.$bvModal.msgBoxConfirm(
          `Delete stream "${this.stream.slug}"?`,
          { okVariant: 'danger', okTitle: 'Delete', 
            title: 'Confirm Deletion'});
        if(confirmation) {
          this.streamAction('delete');
          this.$router.go(-1);
        }
      },
      outputGroupTitle(key) {
        const outputGroups = {
          cdn: 'via Content Delivery Network',
        };

        return outputGroups[key] || key;
      },
    },

    watch: {
      '$route'() {
        this.routeChanged();
      },
    },

    data() {
      return {
        streamError: null,
        streamErrorVisible: false,
      };
    },
  }
</script>

<style scoped>
   .copy-btn {
     background:#e9ecef;
     border:1px solid #ced4da;
     color: #495057;
   }
</style>
