<template>
  <div>
    <b-alert v-if="error" variant="danger" show>{{error}}</b-alert>
    <template v-else>
      <loading-spinner v-if="!apiKeys"/>
      <template v-else>
        <b-table v-if="apiKeys.length > 0" :items="apiKeys" :fields="tableFields">
          <template #cell(actions)="data">
            <a href="#" class="action-btn" 
              @click.prevent="toggleDetail(data.index)">
              <fa-icon icon="eye" v-if="!data.item._showDetails"/>
              <fa-icon icon="eye-slash" v-else/>
            </a>
            <a href="#" class="action-btn" @click.prevent="confirmDeleteKey(data.item)">
              <fa-icon icon="times"/>
            </a>
          </template>

          <template #row-details="row">
            <div class="key-display">
              {{row.item.token}}
            </div>
          </template>
        </b-table>
        <div v-else class="no-keys">
          This user has no API keys.
        </div>
        <div class="button-box mt-3">
          <create-api-key-modal :user="user" @created="reload"/>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
  import io from '@/socket-instance';

  import LoadingSpinner from '@/components/LoadingSpinner';
  import CreateApiKeyModal from '@/components/CreateApiKeyModal';

  export default {
    name: 'UserApiKeyTable',
    props: ['user'],

    components: {
      LoadingSpinner,
      CreateApiKeyModal,
    },

    mounted() {
      this.reload();
    },

    methods: {
      reload() {
        this.apiKeys = null;
        this.error = null;
        io.emit('users/loadUserApiKeys', this.user, (result) => {
          if(result.error) {
            this.error = result.error;
          } else {
            for(let x of result.apiKeys) {
              x._showDetails = false;
            }
            this.apiKeys = result.apiKeys;
          }
        });
      },

      async confirmDeleteKey(key) {
        const confirmation = await this.$bvModal.msgBoxConfirm(
          `Delete API key with comment "${key.comment}"?  This cannot be undone.`,
          { okVariant: 'danger', okTitle: 'Delete API Key',
            title: 'Confirm deletion'});
        if(confirmation) {
          const args = {
            user: this.user,
            jti: key.jti
          };

          io.emit('users/removeUserApiKey', args, (result) => {
            if(result.error) {
              this.error = result.error;
            } else {
              this.reload();
            }
          });
        }
      },

      toggleDetail(index) {
        this.apiKeys[index]._showDetails = !this.apiKeys[index]._showDetails;
      },

    },

    data() {
      return {
        apiKeys: null,
        error: null,

        tableFields: [
          'comment',
          'expires',
          'actions',
        ],
      };
    },
  }
</script>

<style scoped>
  .no-keys {
    color: gray;
    font-style: italic;
  }

  .action-btn {
    color: gray;
    margin-right: 0.5em;
  }

  .action-btn:hover {
    color: black;
  }

  .key-display {
    font: 10pt monospace;
    margin-left: 32px;
    word-wrap: break-word;
    max-width: 600px;
  }
</style>
